import { VOOMA_AI_EMAIL } from '@shared/constants/identifiers';
import { QuoteEventsQuery, QuoteEventType } from '@shared/generated/graphql';
import { getUserColorGroup } from '@shared/utils';
import {
  computeMarginPercent,
  sumRateComponents,
} from '@shared/utils/rates/rates';
import { formatDollars } from 'clerk_common/stringification/numbers';
import {
  formatPickupDate,
  getStopsFromFreightOrder,
} from 'clerk_common/templates/freight_order/fieldExtractors/fieldExtractors';
import { QuoteEventRateType } from 'clerk_common/types';
import {
  QuoteTrailItem,
  QuoteTrailRoute,
  QuoteTrailUser,
  UserRelevantQuoteTrailEventType,
} from 'shared/src/types/quote-event';

interface QuoteEventFromFragmentProps {
  quoteEvents: QuoteTrailItem[];
  route: QuoteTrailRoute;
  originatorName: string;
  pickupDate?: string;
}

export function quoteEventFromFragment(
  quoteEvents: QuoteEventsQuery
): QuoteEventFromFragmentProps {
  const wrangledNotes = quoteEvents.quoteById.quoteNotes.map((note) => ({
    timestamp: new Date(note.createdAt),
    value: note.note,
    type: UserRelevantQuoteTrailEventType.USER_NOTE,
    creator: getCreator(note),
  }));

  const sortedEventsAndNotes = [
    ...quoteEvents.quoteById.quoteEvents.reduce<
      {
        type: UserRelevantQuoteTrailEventType;
        timestamp: Date;
        creator: QuoteTrailUser | null;
      }[]
    >((acc, qe) => {
      const maybeTimestampedEvent = toMaybeTimestampedEvent(qe);
      return maybeTimestampedEvent ? [...acc, ...[maybeTimestampedEvent]] : acc;
    }, []),
    ...wrangledNotes,
  ].sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

  const routeParts = getStopsFromFreightOrder(
    quoteEvents.quoteById.extractedData
  );

  return {
    quoteEvents: sortedEventsAndNotes,
    route: {
      pickup: routeParts[0].address,
      destination: routeParts[routeParts.length - 1].address,
      numberOfStops: routeParts.length,
    },
    originatorName: quoteEvents.quoteById.originator?.name || '',
    pickupDate: formatPickupDate(quoteEvents.quoteById.extractedData, 'LLL d'),
  };
}

function toMaybeTimestampedEvent(
  qe: QuoteEventsQuery['quoteById']['quoteEvents'][0]
) {
  const base = {
    timestamp: new Date(qe.createdAt),
    creator: getCreator(qe),
  };

  const printUser =
    base.creator?.isVoomaUser || !base.creator
      ? 'Vooma'
      : `${base.creator?.firstName} ${base.creator?.lastName}`;

  switch (qe.type) {
    case QuoteEventType.AI_PROCESSING_COMPLETED:
      return {
        type: UserRelevantQuoteTrailEventType.AI_COMPLETED,
        ...base,
        value: `${printUser} completed AI processing`,
      };
    case QuoteEventType.AI_PROCESSING_FAILED:
      return {
        type: UserRelevantQuoteTrailEventType.AI_FAILED,
        ...base,
        value: `${printUser}  reported AI processing failed`,
      };
    case QuoteEventType.MARKED_AS_LOST:
      return {
        type: UserRelevantQuoteTrailEventType.MARKED_AS_LOST,
        ...base,
        value: `${printUser} marked as lost`,
      };
    case QuoteEventType.MARKED_AS_NEW:
      return {
        type: UserRelevantQuoteTrailEventType.MARKED_AS_NEW,
        ...base,
        value: `${printUser} marked as new`,
      };
    case QuoteEventType.MARKED_AS_WON:
      return {
        type: UserRelevantQuoteTrailEventType.MARKED_AS_WON,
        ...base,
        value: `${printUser} marked as won`,
      };
    case QuoteEventType.MARKED_AS_QUOTED:
      return {
        type: UserRelevantQuoteTrailEventType.MARKED_AS_QUOTED,
        ...base,
        value: `${printUser} marked as quoted`,
      };
    case QuoteEventType.PRICING_CHANGED:
      return {
        type: UserRelevantQuoteTrailEventType.PRICING_CHANGED,
        ...base,
        value: getPricingChangedMessage(qe.metadata, base.creator),
      };
    default:
      return null;
  }
}

function getCreator(
  q:
    | QuoteEventsQuery['quoteById']['quoteEvents'][0]
    | QuoteEventsQuery['quoteById']['quoteNotes'][0]
): QuoteTrailUser | null {
  return q.user
    ? {
        firstName: q.user.firstName || q.user.email || '',
        lastName: q.user.lastName || '',
        isVoomaUser: q.user.email === VOOMA_AI_EMAIL,
        colourGroup: getUserColorGroup(q.user.id) || 0,
      }
    : null;
}

function getPricingChangedMessage(
  quoteEventMetadata: QuoteEventsQuery['quoteById']['quoteEvents'][0]['metadata'],
  creator: QuoteTrailUser | null
): string {
  const fromValue = sumRateComponents(quoteEventMetadata.previousValue);
  const toValue = sumRateComponents(quoteEventMetadata.currentValue);
  const diff = computeMarginPercent(fromValue, toValue);
  const op = fromValue > toValue ? 'decreased' : 'increased';
  const creatorName = creator
    ? `${creator?.firstName} ${creator?.lastName}`
    : 'Unknown';

  return `${creatorName} ${op} ${getPricingChangeLabel(
    quoteEventMetadata.rateType
  )} rate by ${diff?.toFixed(2)}%  (from: ${formatDollars(
    fromValue
  )} to ${formatDollars(toValue)})`;
}

function getPricingChangeLabel(rateType: QuoteEventRateType): string {
  return rateType === QuoteEventRateType.CARRIER ? `Buy` : 'Sell';
}
