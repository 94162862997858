import '@/styles/all.scss';
import { init } from '@fullstory/browser';
import { ErrorBoundary } from '@sentry/react';
import { GlobalErrorBoundary } from '@shared/components/GlobalErrorBoundary';
import PrivateRoute from '@shared/components/PrivateRoute/PrivateRoute';
import { FULLSTORY_ORG } from '@shared/constants';
import { AnalyticsContextProvider } from '@shared/contexts/AnalyticsContext';
import { ApolloWrapper } from '@shared/contexts/ApolloWrapper';
import { AuthContextProvider } from '@shared/contexts/AuthContext';
import { useMe } from '@shared/contexts/hooks/useMe';
import { IntercomSupportContextProvider } from '@shared/contexts/IntercomSupportContext';
import { MeContextProvider } from '@shared/contexts/MeContext';
import { AuthorizedIntegrationProvider } from '@shared/contexts/pluginContexts/AuthorizedIntegrationContext';
import { AutomaticNavigator } from '@shared/features/automatic-navigator/AutomaticNavigator';
import QuoteAuditTrail from '@shared/features/quote-trail/QuoteAuditTrail';
import { AuthTokenType, VoomaApiClient } from '@shared/generated/graphql';
import { useInterval } from '@shared/hooks/useInterval';
import { FlowsViewProvider } from '@shared/plugin/contexts/FlowsViewProvider';
import { PlaceSidePanelContextProvider } from '@shared/plugin/contexts/PlaceSidePanelContext';
import { ToastProvider } from '@shared/plugin/contexts/ToastProvider';
import { AccountSettings } from '@shared/plugin/pages/AccountSettings';
import { Home } from '@shared/plugin/pages/Home';
import { OrderSidePanel } from '@shared/plugin/pages/OrderSidePanel';
import { OriginatorOnboarding } from '@shared/plugin/pages/OriginatorOnboarding';
import { Quotes } from '@shared/plugin/pages/Quotes';
import { QuoteSidePanel } from '@shared/plugin/pages/QuoteSidePanel';
import {
  checkClientVersion,
  DEFAULT_VERSION_CHECK_INTERVAL,
} from '@shared/utils/version';
import { DrawerTabValue } from 'clerk_common/constants/urls/queryParams';
import { ORDERS_ROUTE, QUOTES_ROUTE } from 'clerk_common/constants/urls/routes';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AuthorizeMicrosoftNotice } from './components/AuthorizeMicrosoftNotice';
import { OutlookAPIContextProvider } from './contexts/OutlookAPIContextProvider';
import { OutlookThreadContextProvider } from './contexts/OutlookThreadContextProvider';
import { OutlookLogin as Login } from './features/Login';
import { Layout } from './Layout';

const WEB_BASE_URL = import.meta.env.VITE_WEB_BASE_URL;
const LOGIN_URL = `${import.meta.env.VITE_APP_BASE_URL}/login`;
const APOLLO_URI = `${import.meta.env.VITE_API_BASE_URL}/api`;

function App() {
  React.useEffect(() => {
    init({ orgId: FULLSTORY_ORG, recordOnlyThisIFrame: true });
  }, []);
  useInterval(() => {
    checkClientVersion();
  }, DEFAULT_VERSION_CHECK_INTERVAL);

  const location = useLocation();

  return (
    <div className="App">
      <AnalyticsContextProvider apiClient={VoomaApiClient.OUTLOOK}>
        <ErrorBoundary fallback={GlobalErrorBoundary}>
          <AnimatePresence>
            <ToastProvider>
              <AuthContextProvider loginUrl={LOGIN_URL}>
                <ApolloWrapper appName="outlook" httpLinkUri={APOLLO_URI}>
                  <MeContextProvider useMe={useMe}>
                    <IntercomSupportContextProvider>
                      <>
                        <AuthorizedIntegrationProvider
                          type={AuthTokenType.MICROSOFT_GRAPH_API}
                          renderAuthInstructions={() => (
                            <AuthorizeMicrosoftNotice />
                          )}
                        >
                          <OutlookAPIContextProvider>
                            <OutlookThreadContextProvider>
                              {/* NOTE(parlato): Use routes instead of contexts where appropriate */}
                              {/* TODO(parlato): Remove and move to routes once we've implemented route transitions */}
                              <PlaceSidePanelContextProvider>
                                {/* TODO(parlato): Remove and move to routes once we've implemented route transitions */}
                                <FlowsViewProvider>
                                  <AutomaticNavigator>
                                    <Routes
                                      location={location}
                                      key={location.pathname}
                                    >
                                      <Route
                                        path="/login-prompt"
                                        element={<Login />}
                                      />
                                      {/* Private routes */}
                                      <Route
                                        path="/"
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <Layout
                                              webBaseUrl={WEB_BASE_URL}
                                              hasMenu
                                            >
                                              <Home />
                                            </Layout>
                                          </PrivateRoute>
                                        }
                                      />
                                      <Route
                                        path="/settings"
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <Layout webBaseUrl={WEB_BASE_URL}>
                                              <AccountSettings />
                                            </Layout>
                                          </PrivateRoute>
                                        }
                                      />
                                      <Route
                                        path={QUOTES_ROUTE}
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <Layout webBaseUrl={WEB_BASE_URL}>
                                              <Quotes
                                                webBaseUrl={WEB_BASE_URL}
                                              />
                                            </Layout>
                                          </PrivateRoute>
                                        }
                                      />
                                      <Route
                                        path={`${QUOTES_ROUTE}/:quoteId`}
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <Layout webBaseUrl={WEB_BASE_URL}>
                                              <QuoteSidePanel
                                                webBaseUrl={WEB_BASE_URL}
                                              />
                                            </Layout>
                                          </PrivateRoute>
                                        }
                                      />
                                      <Route
                                        path={`${QUOTES_ROUTE}/:quoteId/${DrawerTabValue.AUDIT_TRAIL}`}
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <Layout
                                              webBaseUrl={WEB_BASE_URL}
                                              navigateBackTo="quote-page"
                                            >
                                              <QuoteAuditTrail />
                                            </Layout>
                                          </PrivateRoute>
                                        }
                                      />
                                      <Route
                                        path={`${ORDERS_ROUTE}/:orderId/originator-onboarding/:originatorId`}
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <Layout webBaseUrl={WEB_BASE_URL}>
                                              <OriginatorOnboarding />
                                            </Layout>
                                          </PrivateRoute>
                                        }
                                      />
                                      <Route
                                        path={`${ORDERS_ROUTE}/:orderId`}
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <Layout webBaseUrl={WEB_BASE_URL}>
                                              <OrderSidePanel
                                                webBaseUrl={WEB_BASE_URL}
                                              />
                                            </Layout>
                                          </PrivateRoute>
                                        }
                                      />
                                    </Routes>
                                  </AutomaticNavigator>
                                </FlowsViewProvider>
                              </PlaceSidePanelContextProvider>
                            </OutlookThreadContextProvider>
                          </OutlookAPIContextProvider>
                        </AuthorizedIntegrationProvider>
                      </>
                    </IntercomSupportContextProvider>
                  </MeContextProvider>
                </ApolloWrapper>
              </AuthContextProvider>
            </ToastProvider>
          </AnimatePresence>
        </ErrorBoundary>
      </AnalyticsContextProvider>
    </div>
  );
}

export default App;
