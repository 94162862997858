import { useContext } from 'react';
import { IntercomSupportContext } from '../IntercomSupportContext';

export function useIntercomSupportContext() {
  const context = useContext(IntercomSupportContext);
  if (!context) {
    throw new Error(
      'useIntercomSupportContext must be used within a IntercomSupportContext.Provider'
    );
  }
  return context;
}
