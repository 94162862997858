import { ApolloError } from '@apollo/client';
import { Button } from '@shared/components/button';
import { CircularProgress } from '@shared/components/circular-progress';
import { useToast } from '@shared/components/toast';
import {
  GenerateQuoteResponseMessageDocument,
  GetQuotesDocument,
  QuoteDocument,
  RatingError,
  useComputeRatesMutation,
} from '@shared/generated/graphql';
import { useRateErrorsContext } from '../contexts/RateErrorContext';

type CollectRatesButtonProps = {
  quoteId: string;
};

const removeHtmlTags = (str: string) => str.replace(/<[^>]*>?/gm, '');

export function CollectRatesButton(p: CollectRatesButtonProps) {
  const [computeRates, { loading }] = useComputeRatesMutation();
  const { sendToast } = useToast();
  const { setRateError, clearRateErrors } = useRateErrorsContext();

  const sendErrorToast = (e: ApolloError) => {
    sendToast('Error fetching rates', {
      variant: 'error',
      isDismissible: true,
      description: removeHtmlTags(e.message),
    });
  };

  const handleRatingError = (ratingError: RatingError) => {
    const errorMessage = ratingError.errorMessage
      ? removeHtmlTags(ratingError.errorMessage)
      : 'Unknown error';

    setRateError(ratingError.ratingMethod, errorMessage);
  };

  const compute = async () => {
    const res = await computeRates({
      variables: {
        input: {
          id: p.quoteId,
        },
      },
      onError: sendErrorToast,
      refetchQueries: [
        { query: QuoteDocument, variables: { id: p.quoteId } },
        GetQuotesDocument, // TODO(parlato): This is causing an 'unknown document' error
        GenerateQuoteResponseMessageDocument,
      ],
    });

    const ratingErrors = res?.data?.computeRates.ratingErrors;

    clearRateErrors();
    if (ratingErrors?.length) {
      ratingErrors.forEach(handleRatingError);
    }
  };

  const icon = loading ? (
    <svg>
      <CircularProgress />
    </svg>
  ) : undefined;

  return (
    <Button
      icon={icon}
      iconPosition="right"
      size="small"
      variant="secondary"
      onPress={compute}
    >
      Get rates
    </Button>
  );
}
