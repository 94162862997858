import {
  AddressValue,
  ArrayProps,
  CompletedTemplateChildConfigs,
  MaybeCompletedTemplateChildConfigs,
  OrderingPriorities,
  ParentConfigs,
  TemplateChildConfigs,
} from '../types';

export type FreightOrderTemplate =
  FreightOrderTemplateStructure<TemplateChildConfigs>;

export type CompletedFreightOrderTemplate =
  FreightOrderTemplateStructure<CompletedTemplateChildConfigs>;

export type MaybeCompletedFreightOrderTemplate =
  FreightOrderTemplateStructure<MaybeCompletedTemplateChildConfigs>;

export type MetadataWrapped<T> = {
  _value: WithValuesAndPropsRecursive<T, CompletedTemplateChildConfigs>;
} & ParentConfigs;
export type WithMetadata<T> = WithValuesAndPropsRecursive<
  T,
  CompletedTemplateChildConfigs
>;

export type CompletedFreightOrderValues = FreightOrderTemplateStructureValues<
  string | AddressValue
>;

export type TemplateAddressChild = TemplateChildConfigs;
export type CompletedAddressChild = CompletedTemplateChildConfigs<AddressValue>;
export type AddressChildType = CompletedAddressChild | TemplateAddressChild;

export type WithValuesAndPropsRecursive<T, ChildType> = T extends
  | ChildType
  | AddressChildType
  ? T
  : T extends object | undefined
  ? {
      [P in keyof T]: T[P] extends ChildType | AddressChildType | undefined
        ? T[P]
        : T[P] extends Array<infer U> | undefined
        ? {
            _value: NonNullable<WithValuesAndPropsRecursive<U, ChildType>[]>;
          } & ParentConfigs &
            ArrayProps<NonNullable<WithValuesAndPropsRecursive<U, ChildType>>>
        : {
            _value: NonNullable<WithValuesAndPropsRecursive<T[P], ChildType>>;
          } & ParentConfigs;
    }
  : T;

export type ContactTemplate<ChildType> = {
  name: ChildType;
  phone: ChildType;
  email?: ChildType;
  reference?: ChildType;
};

export type StopTemplate<ChildType> = {
  stopType?: ChildType;
  stopReferenceNumber?: ChildType;
  stopReferenceNumbers?: ReferenceTemplate<ChildType>[];
  location: LocationTemplate<ChildType>;
  date?: ChildType;
  time?: ChildType;
  dateRange?: DateTemplate<ChildType>;
  timeRange?: TimeTemplate<ChildType>;
  schedulingType?: ChildType;
  notes?: ChildType;
  externalNotes?: ChildType;
  contact?: ContactTemplate<ChildType>;
};

export type LocationTemplate<ChildType> = {
  // NOTE(jacob): Unfortunately, there doesn't seem to be a way to
  // constrain AddressChildType based on what was actually passed as
  // ChildType. This means we need to add type assertions downstream
  // so TypeScript knows that the address field actually has a value.
  address: AddressChildType;
  name: ChildType;
  locationCode?: ChildType;
};

export type DateTemplate<ChildType> = {
  startDateOrOnlyDate: ChildType;
  optionalEndDate?: ChildType;
};

export type TimeTemplate<ChildType> = {
  startTimeOrOnlyTime: ChildType;
  optionalEndTime?: ChildType;
};

export type LineItemTemplate<ChildType> = {
  amount: ChildType;
  description: ChildType;
  lineItemType?: ChildType;
};

export type WeightTemplate<ChildType> = {
  weight: ChildType;
  units: ChildType;
};

export type MileageTemplate<ChildType> = {
  value: ChildType;
  units: ChildType;
};

export type DimensionTemplate<ChildType> = {
  value: ChildType;
  units: ChildType;
};

export type DimensionsTemplate<ChildType> = {
  length?: DimensionTemplate<ChildType>;
  width?: DimensionTemplate<ChildType>;
  height?: DimensionTemplate<ChildType>;
};

export type AccessorialTemplate<ChildType> = {
  accessorialDescription: ChildType;
};

export type HazmatClassTemplate<ChildType> = {
  hazardClass: ChildType;
  qualifier: ChildType;
};

export type HazmatTemplate<ChildType> = {
  contact: ContactTemplate<ChildType>;
  weight: WeightTemplate<ChildType>;
  unNumber?: ChildType;
  packingGroup?: ChildType;
  classifications: HazmatClassTemplate<ChildType>[];
};

export type CommodityTemplate<ChildType> = {
  description: ChildType;
  handlingQuantity: ChildType;
  handlingUnits: ChildType;
  pieceQuantity: ChildType;
  pieceUnits: ChildType;
  freightClass?: ChildType;
  nmfcCode?: ChildType;
  hazmat?: HazmatTemplate<ChildType>;
  itemReference?: ChildType;
  weight?: WeightTemplate<ChildType>;
  dimensions?: DimensionsTemplate<ChildType>;
};

export type ReferenceTemplate<ChildType> = {
  referenceType?: ChildType;
  referenceNumber: ChildType;
};

export type TemperatureTemplate<ChildType> = {
  min: ChildType;
  max: ChildType;
  units: ChildType;
};

export type EquipmentTemplate<ChildType> = {
  equipmentType: ChildType;
  temperature?: TemperatureTemplate<ChildType>;
  length?: DimensionTemplate<ChildType>;
  description?: ChildType;
};

export type FreightModeTemplate<ChildType> = {
  mode: ChildType;
  subType?: ChildType;
};

export type FreightOrderTemplateStructureValues<ChildType> = {
  weight?: WeightTemplate<ChildType>;
  commodity?: ChildType;
  commodityDetails?: CommodityTemplate<ChildType>;
  commodities?: CommodityTemplate<ChildType>[];
  dimensions?: DimensionsTemplate<ChildType>;
  equipmentType?: ChildType;
  equipment?: EquipmentTemplate<ChildType>;
  accessorials?: AccessorialTemplate<ChildType>[];
  tenderType: ChildType;
  notes?: ChildType;
  orderReferenceNumber?: ChildType;
  orderReferenceNumbers?: ReferenceTemplate<ChildType>[];
  pickup?: StopTemplate<ChildType>;
  stops: StopTemplate<ChildType>[];
  lineItems?: LineItemTemplate<ChildType>[];
  declaredValue?: ChildType;
  freightMode?: FreightModeTemplate<ChildType>;
  mileage?: MileageTemplate<ChildType>;
  paymentTerms?: ChildType;
};

type FreightOrderTemplateStructure<ChildType> = {
  _value: WithValuesAndPropsRecursive<
    FreightOrderTemplateStructureValues<ChildType>,
    ChildType
  >;
};

const CONTACT_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'name' },
  { key: 'phone' },
  { key: 'email' },
  { key: 'reference' },
];
const STOP_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'stopType' },
  { key: 'location' },
  { key: 'date' },
  { key: 'dateRange' },
  { key: 'time' },
  { key: 'timeRange' },
  { key: 'stopReferenceNumber' },
  { key: 'stopReferenceNumbers' },
  { key: 'contact', subPriorities: CONTACT_ORDERING_PRIORITIES },
  { key: 'notes' },
];
const TEMPERATURE_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'min' },
  { key: 'max' },
  { key: 'units' },
];
const EQUIPMENT_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'equipmentType' },
  { key: 'temperature', subPriorities: TEMPERATURE_ORDERING_PRIORITIES },
];
const WEIGHT_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'value' },
  { key: 'units' },
];
const HAZMAT_CLASS_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'hazardClass' },
  { key: 'qualifier' },
];
const HAZMAT_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'contact', subPriorities: CONTACT_ORDERING_PRIORITIES },
  { key: 'weight', subPriorities: WEIGHT_ORDERING_PRIORITIES },
  { key: 'unNumber' },
  { key: 'packingGroup' },
  { key: 'classifications', subPriorities: HAZMAT_CLASS_ORDERING_PRIORITIES },
];
const COMMODITY_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'itemReference' },
  { key: 'description' },
  { key: 'weight', subPriorities: WEIGHT_ORDERING_PRIORITIES },
  { key: 'handlingQuantity' },
  { key: 'handlingUnits' },
  { key: 'pieceQuantity' },
  { key: 'pieceUnits' },
  { key: 'freightClass' },
  { key: 'nmfcCode' },
  { key: 'hazmat', subPriorities: HAZMAT_ORDERING_PRIORITIES },
];
const DIMENSION_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'value' },
  { key: 'units' },
];
const DIMENSIONS_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'length', subPriorities: DIMENSION_ORDERING_PRIORITIES },
  { key: 'width', subPriorities: DIMENSION_ORDERING_PRIORITIES },
  { key: 'height', subPriorities: DIMENSION_ORDERING_PRIORITIES },
];
const LINE_ITEM_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'lineItemType' },
  { key: 'description' },
  { key: 'amount' },
];
const FREIGHT_MODE_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'mode' },
  { key: 'subType' },
];
const MILEAGE_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'value' },
  { key: 'units' },
];
export const ORDER_REVIEW_FIELD_ORDERING_PRIORITIES: OrderingPriorities = [
  { key: 'tenderType' },
  { key: 'freightMode', subPriorities: FREIGHT_MODE_ORDERING_PRIORITIES },
  { key: 'orderReferenceNumber' },
  { key: 'orderReferenceNumbers' },
  { key: 'equipmentType' },
  { key: 'equipment', subPriorities: EQUIPMENT_ORDERING_PRIORITIES },
  { key: 'mileage', subPriorities: MILEAGE_ORDERING_PRIORITIES },
  { key: 'weight', subPriorities: WEIGHT_ORDERING_PRIORITIES },
  { key: 'commodity' },
  { key: 'commodityDetails', subPriorities: COMMODITY_ORDERING_PRIORITIES },
  { key: 'commodities', subPriorities: COMMODITY_ORDERING_PRIORITIES },
  { key: 'dimensions', subPriorities: DIMENSIONS_ORDERING_PRIORITIES },
  { key: 'pickup', subPriorities: STOP_ORDERING_PRIORITIES },
  { key: 'stops', subPriorities: STOP_ORDERING_PRIORITIES },
  { key: 'accessorials' },
  { key: 'notes' },
  { key: 'lineItems', subPriorities: LINE_ITEM_ORDERING_PRIORITIES },
  { key: 'declaredValue' },
  { key: 'paymentTerms' },
];
