import { isNil, zip } from 'lodash';
import { FormValueChild, ListValuedIntermediateTemplateField } from './types';

export const splitFullyQualifiedPath = (fqPath: string): string[] => {
  // This function splits paths like:
  //    _value.listField._value.2.listChild._value
  // into:
  //    ['_value', 'listField', '_value.2', 'listChild', '_value']
  // The regex matches dots that are not followed by a number, which preserves
  // array indices as a single component. The filter removes the undefined,
  // empty string, and single dot components that are artifacts of regex
  // splitting in JS.
  const regex = /(\.)(?!(\d+))/g;
  return fqPath.split(regex).filter((x) => ![undefined, '', '.'].includes(x));
};

const determineIdx = (
  component: string,
  current: ListValuedIntermediateTemplateField
): string => {
  const idxs = current['_idxs'];
  if (!isNil(idxs)) {
    const idx = idxs.findIndex((idx) => idx === component);
    return `${idx}`;
  } else {
    return component.split('.')[1];
  }
};

export const reduceFullyQualifiedPath = (
  fqPath: string,
  template: FormValueChild
): string | undefined => {
  // NOTE(jacob): I couldn't figure out a good way to avoid using `any` here,
  // since the type of `current` changes as we iterate over the components of
  // the path. This function should still be safe, since we always check isNil
  // before accessing properties of `current`.
  let current = { ...template } as any; // eslint-disable-line @typescript-eslint/no-explicit-any

  const fqComponents: string[] = [];
  const splitPath = splitFullyQualifiedPath(fqPath);

  for (const component of splitPath) {
    const arrayComponentRegex = /(_value|_added)\.(\d+)/;
    const isArrayComponent = arrayComponentRegex.test(component);

    if (isArrayComponent) {
      fqComponents.push(determineIdx(component, current));
    } else if (!component.startsWith('_')) {
      fqComponents.push(component);
    }

    for (const subcomponent of component.split('.')) {
      current = current[subcomponent];
      if (isNil(current)) {
        return undefined;
      }
    }
  }

  return fqComponents.join('.');
};

export const doesComponentMatch = (
  fqComponent?: string,
  condensedComponent?: string
): boolean => {
  if (fqComponent === condensedComponent) {
    return true;
  }
  const isNumericIdx = !isNaN(Number(fqComponent));
  return isNumericIdx && condensedComponent === '<n>';
};

export const doesPathMatch = (
  fqPath: string,
  condensedPath: string,
  template: FormValueChild
): boolean => {
  const condensedComponents = condensedPath.split('.');
  const fqReduced = reduceFullyQualifiedPath(fqPath, template);

  if (isNil(fqReduced)) {
    return false;
  }

  const fqComponents = fqReduced.split('.');

  const zipped = zip(fqComponents, condensedComponents);
  return zipped.every(([fqComp, condComp]) =>
    doesComponentMatch(fqComp, condComp)
  );
};

export const isChildPath = (childPath: string, parentPath: string): boolean => {
  const childComponents = childPath.split('.');
  const parentComponents = parentPath.split('.');

  const zipped = zip(childComponents, parentComponents);

  return zipped.every(
    ([childComp, parentComp]) =>
      doesComponentMatch(childComp, parentComp) || parentComp === undefined
  );
};
