import {
  CompletedTemplateChildConfigs,
  FormValue,
  FormValueChild,
  IntermediateTemplateField,
  ListValuedIntermediateTemplateField,
  ObjectValuedIntermediateTemplateField,
} from 'clerk_common/templates/types';
import { cloneDeep } from 'lodash';
import { isListValued, isObjectValued, updatePath } from './pathHelpers';

export type ObjMutation = (
  template: IntermediateTemplateField,
  path?: string
) => Promise<IntermediateTemplateField>;
export type FieldMutation = (
  child: CompletedTemplateChildConfigs,
  path?: string
) => Promise<CompletedTemplateChildConfigs>;
export type ListMutation = (
  child: ListValuedIntermediateTemplateField,
  path?: string
) => Promise<ListValuedIntermediateTemplateField>;

export interface Mutations {
  obj?: ObjMutation;
  field?: FieldMutation;
  list?: ListMutation;
}

export async function mutateByFieldType(
  template: FormValueChild,
  mutations: Mutations,
  path: string = ''
): Promise<FormValueChild> {
  if (isObjectValued(template)) {
    return await mutateObj(template, mutations, path);
  } else if (isListValued(template)) {
    return await mutateList(template, mutations, path);
  } else {
    return await mutateField(template, mutations, path);
  }
}

async function mutateObjElements(
  d: FormValue,
  mutations: Mutations,
  path: string
): Promise<FormValue> {
  const ret: FormValue = {};
  for (const [key, value] of Object.entries(d)) {
    ret[key] = await mutateByFieldType(value, mutations, updatePath(key, path));
  }
  return ret;
}

async function mutateObj(
  template: ObjectValuedIntermediateTemplateField,
  mutations: Mutations,
  path: string
): Promise<ObjectValuedIntermediateTemplateField> {
  const ret = cloneDeep(template);
  ret._value = await mutateObjElements(
    ret._value,
    mutations,
    updatePath('_value', path)
  );

  if (mutations.obj) {
    return (await mutations.obj(
      ret,
      path
    )) as ObjectValuedIntermediateTemplateField;
  }

  return ret;
}

async function mutateList(
  template: ListValuedIntermediateTemplateField,
  mutations: Mutations,
  path: string
): Promise<ListValuedIntermediateTemplateField> {
  const ret = cloneDeep(template);
  ret._value = await Promise.all(
    template._value.map((x, i) =>
      mutateObjElements(x, mutations, updatePath(`_value.${i}`, path))
    )
  );
  if (template._added) {
    ret._added = await Promise.all(
      template._added.map((x, i) =>
        mutateObjElements(x, mutations, updatePath(`_added.${i}`, path))
      )
    );
  }

  if (mutations.list) {
    return (await mutations.list(
      ret,
      path
    )) as ListValuedIntermediateTemplateField;
  }

  return ret;
}

async function mutateField(
  template: CompletedTemplateChildConfigs,
  mutations: Mutations,
  path: string
): Promise<CompletedTemplateChildConfigs> {
  if (!mutations.field) {
    return template;
  }
  return mutations.field(template, path);
}
