import { JobsState } from '@shared/contexts/pluginContexts/EmailThreadContext';
import { JobSummaryFragment } from '@shared/generated/graphql';
import { min } from 'lodash';
import { FeedItemType, JobCardFeedItem } from '../types';

const getJobFeedItemCreatedAt = (job: JobSummaryFragment): string => {
  if (job.quotes.length > 0) {
    const minQuoteCreatedAt = min(
      job.quotes.map((quote) => quote.createdAtDisplay || quote.createdAt)
    );
    return minQuoteCreatedAt || job.createdAt;
  }

  if (job.orders.length > 0) {
    const minOrderCreatedAt = min(
      job.orders.map((order) => order.createdAtDisplay || order.createdAt)
    );
    return minOrderCreatedAt || job.createdAt;
  }

  return job.createdAt;
};

export const transformThreadJobs = (
  threadJobs: JobsState
): JobCardFeedItem[] => {
  return threadJobs.jobs.map((job) => {
    const jobType = threadJobs.jobTypes[job.id];
    return {
      type: FeedItemType.JOB_CARD,
      createdAt: getJobFeedItemCreatedAt(job),
      // TODO(parlato): Pull the job owner
      //   createdBy: job.createdBy,
      data: {
        job,
        initialJobType: jobType,
      },
    };
  });
};
