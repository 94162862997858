import { Button, JSONDefinedForm } from '@shared/components';
import { renameFormToCorrected } from '@shared/components/json-defined-form/stateManagement/form';
import { EditableUnit } from '@shared/components/json-defined-form/types';
import {
  GenerateQuoteResponseMessageDocument,
  QuoteDocument,
  useUpdateQuoteMutation,
} from '@shared/generated/graphql';
import { Quote } from '@shared/types/quote';
import { StyleProps } from '@shared/utils';
import { CompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { MdClear } from 'react-icons/md';

const QUOTE_REVIEW_FIELD_ORDERING_PRIORITIES = [
  {
    key: 'pickup',
    subPriorities: [
      {
        key: 'location',
      },
      {
        key: 'date',
      },
      {
        key: 'time',
      },
    ],
  },
  {
    key: 'stops',
    subPriorities: [
      {
        key: 'location',
      },
      {
        key: 'date',
      },
      {
        key: 'time',
      },
    ],
  },
  {
    key: 'equipmentType',
  },
  {
    key: 'weight',
  },
  {
    key: 'commodity',
  },
];

export type QuoteReviewDrawerComponentProps = StyleProps & {
  setNotEditing: () => void;
  quote: Quote;
  data: EditableUnit;
  isLoading: boolean;
  isInTrainingMode: boolean;
};

export function QuoteReviewDrawerComponent(
  props: QuoteReviewDrawerComponentProps
) {
  const p = { ...props };
  const [updateQuote, { loading: updateIsLoading }] = useUpdateQuoteMutation();

  const onSave = async (data: EditableUnit) => {
    const dataWithCorrectedFields = renameFormToCorrected(
      data as CompletedFreightOrderTemplate
    );
    console.log('data', dataWithCorrectedFields);
    updateQuote({
      variables: {
        input: {
          id: p.quote?.id,
          extractedData: dataWithCorrectedFields,
        },
      },
      refetchQueries: [
        GenerateQuoteResponseMessageDocument,
        { query: QuoteDocument, variables: { id: p.quote?.id } },
      ],
    });
  };

  return (
    <>
      <div className="relative">
        <div className="absolute w-full flex justify-end pr-2 pt-2">
          <Button
            className=""
            onPress={p.setNotEditing}
            variant="secondary"
            icon={<MdClear />}
            size="xs"
          />
        </div>
      </div>
      <JSONDefinedForm
        data={p.data}
        requiredFields={[]}
        showDebugInfo={false}
        onSave={onSave}
        saveIsLoading={updateIsLoading}
        isInTrainingMode={p.isInTrainingMode}
        orderingPriorities={QUOTE_REVIEW_FIELD_ORDERING_PRIORITIES}
      />
    </>
  );
}
