import { Button } from '@shared/components/button';
import { ExpandablePanel } from '@shared/components/expandable-panel';
import { Text } from '@shared/components/text';
import { Tooltip } from '@shared/components/tooltip';
import { RatingMethod } from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { IntegrationConfig, IntegrationConfigs } from '@shared/types/order';
import {
  DATRatingMethod,
  GreenscreensRatingMethod,
  Quote,
} from '@shared/types/quote';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { getMileageRate } from '@shared/utils/rates/rateDetails';
import { ratingMethodToLabel } from '@shared/utils/rates/rates';
import { usdFormatterNoCents } from 'clerk_common/stringification/numbers';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { MdOutlineOpenInNew } from 'react-icons/md';
import {
  PiCaretDownFill,
  PiCaretUpFill,
  PiWarningCircleFill,
} from 'react-icons/pi';
import { useRateErrorsContext } from '../../contexts/RateErrorContext';
import { getGroupedRatesUrl } from '../../utils/getGroupedRatesUrl';
import { MarketConditionsContainer } from '../market-conditions';
import { useMarketConditionsContext } from '../market-conditions/contexts/MarketConditionsContext';
import { ratingMethodToIntegrationType } from '../market-conditions/MarketConditionsContainer';
import { RateDetail } from '../rate-detail/RateDetail';
import { RateError } from '../rate-error/RateError';
import { GroupedRate } from './types';

const ROOT = makeRootClassName('GroupedRates');
const el = makeElementClassNameFactory(ROOT);

const getRatingMethodIntegrationConfig = (
  ratingMethod: RatingMethod,
  integrationConfigs?: IntegrationConfigs
): IntegrationConfig | undefined => {
  const integrationType = ratingMethodToIntegrationType(ratingMethod);
  if (!integrationConfigs?.integrations?.length) return undefined;
  return integrationConfigs.integrations.find(
    (ic) => ic.integrationType === integrationType
  );
};

export const selectDefaultRate = ({
  rates,
  ratingMethod,
}: {
  rates: GroupedRate[];
  ratingMethod: RatingMethod;
}): GroupedRate | undefined => {
  switch (ratingMethod) {
    case RatingMethod.DAT:
      return (
        rates.find((r) => r.metadata?.ratingMethod === DATRatingMethod.RATE) ||
        rates[0]
      );

    case RatingMethod.GREENSCREENS:
      return (
        rates.find(
          (r) => r.metadata?.ratingMethod === GreenscreensRatingMethod.DEFAULT
        ) || rates[0]
      );

    default:
      return rates[0];
  }
};

type GroupedRatesProps = {
  rates: GroupedRate[];
  ratingMethod: RatingMethod;
  isDATRateViewAutomationEnabled: boolean;
  isDATLoadSearchAutomationEnabled: boolean;
  onRateSelect: (rateId: string) => Promise<void>;
  onExpandGroupedRates: () => void;
  isDisabled?: boolean;
  quote: Quote;
};

const RateLogo = ({ rateMethod }: { rateMethod?: RatingMethod }) => {
  if (!rateMethod) return null;

  const gsTooltip = 'Rate sourced from Greenscreens.ai';
  const datTooltip = 'Rate sourced from DAT';
  const sunsetTooltip = 'Rate sourced from Sunset';
  const tabiTooltip = 'Rate sourced from Tabi';

  switch (rateMethod) {
    case RatingMethod.DAT:
      return <img src="/dat.png" alt={datTooltip} />;
    case RatingMethod.GREENSCREENS:
      return <img src="/greenscreens.png" alt={gsTooltip} />;
    case RatingMethod.SUNSET:
      return <img src="/sunset.png" alt={sunsetTooltip} />;
    case RatingMethod.TABI:
      return <img src="/tabi.png" alt={tabiTooltip} />;
    default:
      return null;
  }
};

export const GroupedRates = (p: GroupedRatesProps) => {
  const { rateErrors } = useRateErrorsContext();
  const defaultRate = selectDefaultRate({
    rates: p.rates,
    ratingMethod: p.ratingMethod,
  });
  const defaultExpanded = Boolean(rateErrors[p.ratingMethod]) || false;
  const [expanded, setExpanded] = useState(defaultExpanded);
  const mileageRate = getMileageRate({ metadata: defaultRate?.metadata });
  const groupUrl = useMemo(() => getGroupedRatesUrl(p), [p]);
  const integrationConfig = getRatingMethodIntegrationConfig(
    p.ratingMethod,
    p.quote.organization.integrationConfigs
  );
  const { shouldFetchMarketConditions } = integrationConfig || {};
  const { fetchMarketConditionsMutation, loading } =
    useMarketConditionsContext();
  const { track } = useAnalytics();

  useEffect(() => {
    if (expanded && !loading && shouldFetchMarketConditions) {
      // NOTE(parlato): We lazy fetch market conditions only once
      // the user expands the panel (all integrations with market
      // conditions enabled will be fetched)
      fetchMarketConditionsMutation();
    }
  }, [expanded, shouldFetchMarketConditions]);

  return (
    <div className={ROOT}>
      <ExpandablePanel
        handleChange={(expanded) => {
          track(`Rate Widget ${expanded ? 'Expanded' : 'Collapsed'}`, {
            name: ratingMethodToLabel(p.ratingMethod),
            quoteId: p.quote.id,
          });
          expanded && p.onExpandGroupedRates();
          setExpanded(expanded);
        }}
        defaultExpanded={defaultExpanded}
        summary={
          <div className={clsx(el`summary`, expanded && 'expanded')}>
            <div className={el`rate-header`}>
              <div className={el`rate-logo`}>
                <RateLogo rateMethod={p.ratingMethod}></RateLogo>
                {rateErrors[p.ratingMethod] && (
                  <Tooltip isInstant content={rateErrors[p.ratingMethod]}>
                    <div className={el`rate-error-badge`}>
                      <PiWarningCircleFill
                        className={el`rate-error-icon`}
                        size={16}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
              <Text isHeavy type="body-sm">
                {ratingMethodToLabel(p.ratingMethod)}
              </Text>
            </div>
            <div className={el`rate-and-caret`}>
              {defaultRate && (
                <div className={el`rate`}>
                  <div className={el`rate-dollars`}>
                    <Text isHeavy type="body-xs">
                      {usdFormatterNoCents.format(defaultRate.rate)}
                    </Text>
                  </div>
                  {mileageRate && (
                    <div className={el`rate-mileage`}>
                      <Text type="custom">{mileageRate}</Text>
                    </div>
                  )}
                </div>
              )}
              <div>{expanded ? <PiCaretUpFill /> : <PiCaretDownFill />}</div>
            </div>
          </div>
        }
        details={
          <div className={el`details`}>
            {rateErrors[p.ratingMethod] && (
              <div className={el`rate-error-container`}>
                <RateError
                  errorMessage={rateErrors[p.ratingMethod]}
                  integrationName={ratingMethodToLabel(p.ratingMethod)}
                  fetchedEntity="rates"
                />
              </div>
            )}
            {p.rates.map((rate) => {
              return (
                <RateDetail
                  onPress={() => p.onRateSelect(rate.id)}
                  isDisabled={p.isDisabled}
                  key={rate.key}
                  label={rate.label}
                  rate={rate.rate}
                  ratingMethod={p.ratingMethod}
                  metadata={rate.metadata}
                />
              );
            })}
            {shouldFetchMarketConditions && (
              <MarketConditionsContainer
                integrationType={ratingMethodToIntegrationType(p.ratingMethod)}
              />
            )}
            {groupUrl && (
              <div className={el`group-url`}>
                <Button
                  size="xs"
                  onPress={() => window.open(groupUrl, '_blank')}
                  icon={<MdOutlineOpenInNew className="text-brand-500" />}
                  tooltip="Open rate source in new tab"
                  variant="secondary"
                  iconPosition="right"
                  className={el`group-url-link`}
                >
                  View rate source
                </Button>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};
