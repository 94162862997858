import { FlowIcon } from '@shared/components/flow-icon/FlowIcon';
import { useFlowsViewContext } from '@shared/contexts/FlowsViewContext';
import { syncState } from '@shared/hooks/updateChecker';
import { useDeepCompareEffect } from '@shared/hooks/useDeepCompareEffect';
import { StyleProps } from '@shared/utils';
import { reduceFullyQualifiedPath } from 'clerk_common/templates/path';
import { FormValueChild } from 'clerk_common/templates/types';
import { useState } from 'react';
import { Path, useFormContext } from 'react-hook-form';
import { useInternalFormContext } from '../internalFormContext/InternalFormContext';
import { EditableJSONField, FORM_STATE_FIELD } from '../types';
import { BasicTextField } from './BasicTextField';
import { DebugTooltip } from './DebugTooltip';
import { FieldLabel } from './FieldLabel';
import { determineConfidenceBucket, isRequiredField } from './fieldLogic';
import { SelectField, SELECT_TYPES } from './Select';

type FormFieldProps = StyleProps & {
  data: EditableJSONField;
  prefix?: string;
  hideLabel?: boolean;
};

export function FormField({
  data,
  prefix,
  hideLabel,
  className,
}: FormFieldProps) {
  const { requiredFields, onFieldEdit, save, inTrainingMode } =
    useInternalFormContext();
  const [confidenceBucket, setConfidenceBucket] = useState<'high' | 'low'>(
    determineConfidenceBucket(data)
  );
  const { setValue, clearErrors } = useFormContext<EditableJSONField>();
  const [fieldValue, setFieldValue] = useState(data[FORM_STATE_FIELD] ?? '');
  const isRequired = isRequiredField(requiredFields, prefix);
  const [fieldValueOnFocus, setFieldValueOnFocus] = useState(fieldValue);
  const { extractedData } = useFlowsViewContext();

  useDeepCompareEffect(() => {
    // TODO(mike): I think syncState is only needed for fieldValue,
    // but I don't want to change this the night before a big go-live
    setConfidenceBucket((val) =>
      syncState(val, data, determineConfidenceBucket, 'Confidence')
    );
    setFieldValue((val) =>
      syncState(val, data, (d) => d[FORM_STATE_FIELD] ?? '', 'Value')
    );
  }, [data]);

  const markAsCorrect = () => {
    setConfidenceBucket('high');
    setValue(
      `${prefix}${FORM_STATE_FIELD}` as Path<EditableJSONField>,
      data[FORM_STATE_FIELD]
    );
    save();
  };

  const clear = () => {
    setFieldValue('');
    setConfidenceBucket('high');
    setValue(`${prefix}${FORM_STATE_FIELD}` as Path<EditableJSONField>, '');
    save();
  };

  const onTouchField = () => {
    setConfidenceBucket('high');
  };
  const onChange = (e: string) => {
    setFieldValue(e);
    setValue(`${prefix}${FORM_STATE_FIELD}` as Path<EditableJSONField>, e);
    clearErrors(`${prefix}${FORM_STATE_FIELD}` as Path<EditableJSONField>);
  };

  const promptForComment = () => {
    const comment = window.prompt(
      'Please tell us why you made this change so we can improve your AI performance.'
    );
    if (comment) {
      setValue(
        `${prefix}_correctionRationale` as '_correctionRationale',
        comment
      );
    }
  };

  const onFocusChange = (e: boolean) => {
    if (!e) {
      if (fieldValue !== fieldValueOnFocus) {
        if (inTrainingMode) promptForComment();
        onFieldEdit?.();
      }
      save();
    } else {
      setFieldValueOnFocus(fieldValue);
    }
  };

  const label = (
    <FieldLabel
      onMarkAsCorrect={markAsCorrect}
      label={data._display?.name ?? ''}
      confidence={confidenceBucket}
    />
  );

  const reducedPath = reduceFullyQualifiedPath(
    prefix ?? '',
    extractedData as FormValueChild
  );

  for (const t of SELECT_TYPES) {
    if (prefix?.includes(t)) {
      return (
        <DebugTooltip data={data}>
          <SelectField
            fieldValue={fieldValue}
            data={data}
            prefix={prefix}
            label={label}
            hideLabel={hideLabel}
            isRequired={isRequired}
            confidenceBucket={confidenceBucket}
            onChange={(e) => {
              onTouchField();
              onChange(e);
              onFocusChange(false);
            }}
            selectType={t}
            className={className}
            wrapperChildren={
              reducedPath ? <FlowIcon fieldPath={reducedPath} /> : undefined
            }
            wrapperChildrenPlacement="outside"
          />
        </DebugTooltip>
      );
    }
  }

  return (
    <DebugTooltip data={data}>
      <BasicTextField
        fieldValue={fieldValue}
        data={data}
        prefix={prefix}
        label={label}
        hideLabel={hideLabel}
        isRequired={isRequired}
        confidenceBucket={confidenceBucket}
        onTouchField={onTouchField}
        onChange={onChange}
        onFocusChange={onFocusChange}
        clear={clear}
        className={className}
        wrapperChildren={
          reducedPath ? <FlowIcon fieldPath={reducedPath} /> : undefined
        }
        wrapperChildrenPlacement="outside"
      />
    </DebugTooltip>
  );
}
