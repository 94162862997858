import { RatingMethod } from '@shared/generated/graphql';
import { Quote } from '@shared/types/quote';
import { GroupedRate } from '../components/grouped-rates/types';
import { getDATLoadSearchUrl, getDATRateViewUrl } from './getDATRateUrl';

export const maybeGetDATRateUrl = (
  quote: Quote,
  ratingMethod?: RatingMethod,
  isRateViewEnabled?: boolean,
  isLoadSearchEnabled?: boolean
): string | undefined => {
  if (ratingMethod === RatingMethod.DAT) {
    if (isRateViewEnabled) {
      return getDATRateViewUrl(quote);
    }
    if (isLoadSearchEnabled) {
      return getDATLoadSearchUrl(quote);
    }
  }

  return undefined;
};

export const getGroupedRatesUrl = ({
  isDATRateViewAutomationEnabled,
  isDATLoadSearchAutomationEnabled,
  rates,
  ratingMethod,
  quote,
}: {
  isDATRateViewAutomationEnabled: boolean;
  isDATLoadSearchAutomationEnabled: boolean;
  rates: GroupedRate[];
  ratingMethod: RatingMethod;
  quote: Quote;
}): string | undefined => {
  const datUrl =
    isDATRateViewAutomationEnabled || isDATLoadSearchAutomationEnabled
      ? maybeGetDATRateUrl(
          quote,
          ratingMethod,
          isDATRateViewAutomationEnabled,
          isDATLoadSearchAutomationEnabled
        )
      : undefined;

  if (datUrl) return datUrl;

  return rates.find((r) => r.metadata?.url)?.metadata?.url;
};
