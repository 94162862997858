import { ExternalMessageClassification } from 'clerk_common';
import { CONFIDENCE_THRESHOLD, ConversationMessage } from '../types';

export const isValidType = (c: ExternalMessageClassification) =>
  c?.type &&
  ![
    'MISSING_CLASS',
    'UNKNOWN',
    'NOT_ENOUGH_INFORMATION',
    'NOT_APPLICABLE',
  ].includes(c?.type);

export const hasProbableClassification = (message: ConversationMessage) => {
  if (!message.classifications?.classes) return false;

  return message.classifications.classes.some(
    (c: ExternalMessageClassification) => {
      const confidenceMeetsThreshold =
        c?.confidence && c?.confidence > CONFIDENCE_THRESHOLD;
      return isValidType(c) && confidenceMeetsThreshold;
    }
  );
};
