import { InputBase } from '@mui/material';
import { svgLogoCollapsed } from '@shared/assets/icons/svgs';
import { Text } from '@shared/components/text';
import { useEffect, useState } from 'react';

import { Button } from '@shared/components/button';
import { Modal } from '@shared/components/modal';
import { useMe } from '@shared/contexts/hooks/useMe';
import { useUpdateUserSettings } from './hooks/useUpdateUserSettings';

export function ConfigureAutoResponse() {
  const { me } = useMe();
  const [open, setOpen] = useState(false);
  const [prompt, setPrompt] = useState(
    me?.settings?.autoResponseInstructions || ''
  );
  const updateUserSettings = useUpdateUserSettings();

  useEffect(() => {
    setPrompt(me?.settings?.autoResponseInstructions || '');
  }, [open]);

  return (
    <>
      <Modal.Root open={open}>
        <Modal.Content className="p-4 text-gray-500">
          <div className="flex justify-end">
            <Button className="" onPress={() => setOpen(false)} size="xs">
              x puit icon
            </Button>
          </div>
          <Text type="body-sm" className="text-gray-500">
            Tell the Vooma Agent
            <span className="inline-block">{svgLogoCollapsed}</span> when to
            send quotes automatically:
          </Text>
          <InputBase
            fullWidth
            margin="dense"
            multiline
            placeholder={`${
              !me?.settings?.autoResponseInstructions &&
              'For example, "All quotes under 1000 dollars", or "No quote that uses a Reefer"'
            }`}
            value={prompt}
            minRows={4}
            size="small"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPrompt(event.target.value);
            }}
            className="my-2 w-full rounded border"
            sx={{
              fontSize: '12px',
              padding: '8px',
            }}
            inputProps={{ className: 'p-2 w-full' }}
          />
          <div className="flex justify-end">
            <Button
              className=""
              onPress={() => {
                me?.id &&
                  void updateUserSettings({
                    variables: {
                      input: {
                        id: me?.id,
                        settings: { autoResponseInstructions: prompt },
                      },
                    },

                    refetchQueries: ['Me'],
                  });
                setOpen(false);
              }}
              size="xs"
              variant="primary"
            >
              Submit
            </Button>
          </div>
        </Modal.Content>
      </Modal.Root>
      <Button
        size="medium"
        tooltip="Manage integrations in web app"
        variant="tertiary"
        iconPosition="right"
        className="mb-2"
        onPress={() => setOpen(true)}
      >
        Configure Auto Reply
      </Button>
    </>
  );
}
