import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { FormField } from '../../fields/FormField';
import { EditableJSONField } from '../../types';

const ROOT = makeRootClassName('TimeEntity');
export const el = makeElementClassNameFactory(ROOT);

type AppointmentTimeProps = {
  time: EditableJSONField;
  prefix?: string;
};

export function AppointmentTime({ time, prefix }: AppointmentTimeProps) {
  return (
    <FormField data={time} prefix={`${prefix}_value.startTimeOrOnlyTime.`} />
  );
}
