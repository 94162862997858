import { Button, OrderStatusChip, Text } from '@shared/components';
import { Dropdown } from '@shared/components/plugin-components';
import { useToast } from '@shared/components/toast';
import {
  OrderEvent,
  useDeleteOrderMutation,
  useRepeatOrderMutation,
} from '@shared/generated/graphql';
import { abbreviateId } from '@shared/utils/stringification/id';
import { useNavigate } from 'react-router-dom';

import { QueryParamName } from 'clerk_common/constants/urls/queryParams';
import { ORDERS_ROUTE } from 'clerk_common/constants/urls/routes';
import { LuMoreVertical } from 'react-icons/lu';
import { MdOutlineOpenInNew } from 'react-icons/md';

function OrderSidePanelHeader({
  webBaseUrl,
  orderId,
  orderEvents,
}: {
  webBaseUrl: string;
  orderId?: string;
  orderEvents: OrderEvent[];
}) {
  const navigate = useNavigate();

  const [deleteOrder] = useDeleteOrderMutation();
  const [repeatOrder] = useRepeatOrderMutation();

  const { sendToast } = useToast();

  const sendErrorToast = (e: Error) => {
    sendToast('Something went wrong', {
      description: e.message,
      variant: 'error',
      isDismissible: true,
    });
  };

  const handleQuickDuplicate = async () => {
    if (!orderId) return;
    await repeatOrder({
      variables: { input: { id: orderId, numRepeats: 1 } },
      refetchQueries: ['GetJobs'],
    })
      .then(() => {
        sendToast('Order has been duplicated!', { variant: 'success' });
        navigate(-1);
      })
      .catch((e) => sendErrorToast(e));
  };

  const handleDeleteOrder = async () => {
    if (orderId) {
      await deleteOrder({
        variables: { input: { id: orderId } },
        refetchQueries: ['GetOrders'],
      })
        .then(() => {
          sendToast('Order has been deleted!', { variant: 'success' });
        })
        .catch((e) => sendErrorToast(e));
    }
    navigate(-1);
  };

  const abbreviatedId = abbreviateId(orderId || '');

  return (
    <>
      <OrderStatusChip orderEvents={orderEvents ?? []} />
      <a
        href={`${webBaseUrl}${ORDERS_ROUTE}?${QueryParamName.ORDER_ID}=${orderId}`}
        target="_blank"
        rel="noreferrer"
      >
        <Button
          size="xs"
          icon={<MdOutlineOpenInNew className="text-brand-500" />}
          tooltip="Open in web app"
          variant="secondary"
          iconPosition="right"
        >
          Vooma
        </Button>
      </a>
      <Dropdown
        icon={<LuMoreVertical size={12} />}
        items={[
          {
            node: (
              <div className="py-2" onClick={handleQuickDuplicate}>
                <Text type="body-xs">Quick duplicate</Text>
              </div>
            ),
          },
          {
            node: (
              <div className="py-2" onClick={handleDeleteOrder}>
                <Text type="body-xs" className="text-danger-500">
                  Delete order
                </Text>
              </div>
            ),
          },
          {
            node: (
              <div
                className="py-2 text-gray-400 cursor-default"
                onClick={() => navigator.clipboard.writeText(orderId || '')}
              >
                <Text type="body-xs">Order {abbreviatedId}</Text>
              </div>
            ),
          },
        ]}
        position="right-0"
      />
    </>
  );
}

export default OrderSidePanelHeader;
