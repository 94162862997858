import { FetchResult } from '@apollo/client';
import { Text } from '@shared/components';
import {
  CircularProgress,
  SlideOver,
} from '@shared/components/plugin-components';
import { useToast } from '@shared/components/toast';
import { OriginatorDetails } from '@shared/features';
import {
  JobType,
  ProcessingStatus,
  UpdateOrderInput,
  UpdateOrderMutation,
  useOrderQuery,
  useUpdateOrderMutation,
} from '@shared/generated/graphql';

import { useIntercomSupportContext } from '@shared/contexts/hooks/useIntercomSupportContext';
import { orderFromFragment } from '@shared/graphql/fromFragments/order';
import useInitializeTemplate from '@shared/graphql/hooks/templates/useInitializeTemplate';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { useSaveContactCue } from '@shared/hooks/useSaveContactCue';
import {
  ActionRegistryContextProvider,
  useActionRegistryContext,
} from '@shared/plugin/contexts/ActionRegistryContext';
import useOriginators from '@shared/plugin/hooks/useOriginators';
import { shouldShowReplicatePrompt } from '@shared/plugin/utils/shouldShowReplicatePrompt';
import { Order } from '@shared/types/order';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OriginatorOnboardingType } from '../OriginatorOnboarding/components/OriginatorOnboardingWizard/types';
import { useInitiateOriginatorOnboarding } from '../OriginatorOnboarding/hooks/useInitiateOriginatorOnboarding';
import { getOnboardingRoute } from '../OriginatorOnboarding/OriginatorOnboarding';
import { MultiOrderBanner } from './components/MultiOrderBanner';
import OrderReviewForm from './components/OrderReviewForm';
import { useReprocessOrder } from './hooks/useReprocessOrder';
import OrderSidePanelHeader from './OrderSidePanelHeader';

const POLLING_INTERVAL = 10 * 1000;

const ROOT = makeRootClassName('OrderSidePanel');
export const el = makeElementClassNameFactory(ROOT);

type OriginitorOption = {
  id: string;
  label: string;
  details: string;
};
interface OrderSidePanelProps {
  order: Order;
  originatorOptions: OriginitorOption[];
  loading: boolean;
  updateOriginator: (
    args: OriginatorIdentifiers
  ) => Promise<void | FetchResult<UpdateOrderMutation>>;
  onChangeOriginator: (originatorId: string) => void;
  handleOriginatorSearch: (query?: string) => void;
}

const OrderSidePanel = ({
  order,
  originatorOptions,
  loading,
  updateOriginator,
  onChangeOriginator,
  handleOriginatorSearch,
}: OrderSidePanelProps) => {
  const [showReplicatePrompt, setShowReplicatePrompt] = useState(
    shouldShowReplicatePrompt(order)
  );
  const { handleReplicate } = useActionRegistryContext();
  const { sendToast } = useToast();
  const { initiateOriginatorOnboarding } = useInitiateOriginatorOnboarding({
    orderId: order.id,
    organizationId: order.organization.id,
  });
  const navigate = useNavigate();
  useIntercomSupportContext();
  const { initializeTemplate, originatorHasTemplate } = useInitializeTemplate({
    originatorId: order.originator.id,
  });

  const sendErrorToast = (e: Error) => {
    sendToast('Something went wrong', {
      description: e.message,
      variant: 'error',
      isDismissible: true,
    });
  };

  const handleBuildTheRest = async (orderId: string) => {
    await handleReplicate(orderId).then(() => {
      setShowReplicatePrompt(false);
    });
  };

  const handleChangeOriginator = async (id: string) => {
    await updateOriginator({ originatorId: id }).catch((e) =>
      sendErrorToast(e)
    );
    await onChangeOriginator(id);
  };

  const handleChangeTransmitId = async (id: string) => {
    await updateOriginator({ transmitId: id }).catch((e) => sendErrorToast(e));
  };

  return (
    <div className={el`order-container`}>
      <div className={el`order-header`}>
        {showReplicatePrompt && (
          <MultiOrderBanner buildTheRest={() => handleBuildTheRest(order.id)} />
        )}
        <OriginatorDetails
          originator={order.originator}
          originatorOptions={originatorOptions}
          updateOriginator={handleChangeOriginator}
          updateTransmitId={handleChangeTransmitId}
          loading={loading}
          transmitId={order.transmitId}
          customerDetailsCTA={'Configure customer'}
          navigateToOriginatorDetails={async () => {
            if (order.originator?.id) {
              if (!originatorHasTemplate) {
                await initializeTemplate();
              }

              navigate(
                getOnboardingRoute(
                  OriginatorOnboardingType.EXISTING_ORIGINATOR,
                  order.id,
                  order.originator?.id
                )
              );
            }
          }}
          initiateOriginatorOnboarding={initiateOriginatorOnboarding}
          handleSearch={handleOriginatorSearch}
        />
      </div>
      <div className={el`order-details`}>
        {order.status === ProcessingStatus.PROCESSING ? (
          <div className="flex flex-col w-full gap-4 items-center justify-center h-48">
            <Text className="text-gray-500" type="body-sm">
              Order is being built...
            </Text>
            <CircularProgress />
          </div>
        ) : (
          <OrderReviewForm order={order} isLoading={loading} />
        )}
      </div>
    </div>
  );
};

type OriginatorIdentifiers = Pick<
  UpdateOrderInput,
  'originatorId' | 'transmitId'
>;
type OrderSidePanelContainerProps = {
  webBaseUrl: string;
};
const OrderSidePanelContainer = ({
  webBaseUrl,
}: OrderSidePanelContainerProps) => {
  const { page } = useAnalytics();
  const navigate = useNavigate();
  const { orderId } = useParams();
  // TODO(parlato): useOrderQuery
  const {
    data,
    loading: loadingOrder,
    stopPolling,
    startPolling,
  } = useOrderQuery({
    variables: {
      id: orderId ?? '',
    },
    pollInterval: POLLING_INTERVAL,
    skip: !orderId,
  });
  const [originatorsSearchTerm, setOriginatorsSearchTerm] = useState<string>();
  const { originators, isLoading: loadingOriginators } = useOriginators({
    organizationId: data?.orderById?.organization?.id,
    searchTerm: originatorsSearchTerm,
  });
  const originatorOptions = originators.map((o) => ({
    id: o.id,
    label: o.name,
    details: o.transmitIds?.join(','),
  }));

  const handleOriginatorSearch = (query?: string) => {
    setOriginatorsSearchTerm(query);
  };

  const [updateOrder] = useUpdateOrderMutation();

  const { promptToReprocess, loading: loadingReprocess } =
    useReprocessOrder(orderId);

  const { sendToast } = useToast();
  const { handleSendCue } = useSaveContactCue();

  const sendErrorToast = (e: Error) => {
    sendToast('Something went wrong', {
      description: e.message,
      variant: 'error',
      isDismissible: true,
    });
  };

  useEffect(() => {
    if (orderId) {
      startPolling(POLLING_INTERVAL);
    } else {
      stopPolling();
    }

    return () => stopPolling();
  }, [orderId]);

  const order = data?.orderById ? orderFromFragment(data?.orderById) : null;

  const updateOriginator = async ({
    originatorId,
    transmitId,
  }: OriginatorIdentifiers) => {
    const id = data?.orderById?.id;
    if (!id) return;
    const res = await updateOrder({
      variables: { input: { id, originatorId, transmitId } },
    }).catch((e) => sendErrorToast(e));

    const previousOriginatorId = data?.orderById?.originator?.id;
    if (!previousOriginatorId && !isNil(originatorId)) {
      // NOTE(max): We intentionally want to let this run async.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleSendCue({ job: order?.job, originatorId, jobType: JobType.ORDERS });
    }

    return res;
  };

  const onChangeOriginator = (originatorId: string) => {
    if (!orderId) return;

    const originator = originators.find((o) => o.id === originatorId);
    if (!originator) return;

    if (originator.hasExtractedFieldsConfigured) {
      promptToReprocess();
      return;
    }

    const route = getOnboardingRoute(
      OriginatorOnboardingType.FIRST_ORDER,
      orderId,
      originatorId
    );
    navigate(route);
  };

  useEffect(() => {
    if (order?.id) {
      page('Order', { orderId: order?.id });
    }
  }, [order?.id]);

  const loading = loadingOrder || loadingOriginators;

  return (
    <ActionRegistryContextProvider>
      <SlideOver
        title={''}
        show={true}
        onClose={() => navigate(-1)}
        header={
          <OrderSidePanelHeader
            webBaseUrl={webBaseUrl}
            orderEvents={data?.orderById.orderEvents || []}
            orderId={order?.id}
          />
        }
      >
        {loading && !order && (
          <div className="w-full h-1/3 flex items-center justify-center">
            <CircularProgress size="md" />
          </div>
        )}
        {!loading && !order && <div className="p-2">Could not find order</div>}
        {order && (
          <OrderSidePanel
            order={order as Order}
            originatorOptions={originatorOptions}
            updateOriginator={updateOriginator}
            loading={loading || loadingReprocess}
            onChangeOriginator={onChangeOriginator}
            handleOriginatorSearch={handleOriginatorSearch}
          />
        )}
      </SlideOver>
    </ActionRegistryContextProvider>
  );
};

export default OrderSidePanelContainer;
