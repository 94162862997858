import { Text, TextField } from '@shared/components';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { MdCheckCircle } from 'react-icons/md';

const ROOT = makeRootClassName('SearchInput');
export const el = makeElementClassNameFactory(ROOT);

export type SearchInputOption = {
  label: string;
  details: string;
  id: string;
  icon?: React.ReactNode;
};

interface OptionProps {
  selected: boolean;
  disabled?: boolean;
  option: SearchInputOption;
  onSelect?: () => void;
}

export const Option = (p: OptionProps) => {
  return (
    <div
      className={clsx(
        el`option`,
        p.option?.icon && 'has-icon',
        p.selected && 'selected'
      )}
      onClick={(e) => {
        e.stopPropagation();
        if (!p.disabled) p.onSelect?.();
      }}
    >
      <div className="flex flex-row">
        {p.option?.icon ? (
          <div className={el`icon`}>{p.option.icon}</div>
        ) : null}
        <div className="flex flex-col items-start gap-1">
          <Text className={clsx(el`label`)} type="body-sm">
            {p.option.label}
          </Text>
          <Text
            type="body-xs"
            className={clsx(el`details`, p.selected && 'selected')}
          >
            {p.option.details}
          </Text>
        </div>
      </div>
      {p.selected && (
        <MdCheckCircle size={20} className={clsx(el`check-icon`)} />
      )}
    </div>
  );
};

interface SearchInputProps {
  handleSearch: (query?: string) => void;
  handleSelect: (id: string) => void;
  onClose?: () => void;
  debounce: boolean;
  autofocus?: boolean;
  selectedOption?: SearchInputOption;
  options: SearchInputOption[];
  label?: string;
  placeholder?: string;
  isTransparent?: boolean;
  hideSelected?: boolean;
  initializeSearchWithValue?: boolean;
  loading?: boolean;
  optionsClassName?: string;
  inputWrapperClassName?: string;
  iconPath?: string;
}

export const SearchInput = (p: SearchInputProps) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState<string | undefined>(
    p.selectedOption?.label
  );

  const handleSearch = useCallback(
    p.debounce ? debounce(p.handleSearch, 500) : p.handleSearch,
    [p.debounce, p.handleSearch]
  );

  const handleInputChange = (val: string) => {
    setValue(val);
    handleSearch(val);
  };

  const handleClose = (val?: string) => {
    handleSearch();
    setFocused(false);
    setValue(val || p.selectedOption?.label);
    p.onClose?.();
  };

  const filteredOptions = p.options.filter(
    (o) => o.id !== p.selectedOption?.id
  );

  const showSelected = !p.hideSelected && p.selectedOption?.id;
  const showNoResults = value && !p.loading && filteredOptions.length === 0;
  const showOptions =
    !p.loading && !showNoResults && filteredOptions.length > 0;
  const showDropdown =
    focused && (p.loading || showSelected || showNoResults || showOptions);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleClose();
    }
  };

  return (
    <div className={clsx(ROOT, p.isTransparent && 'is-transparent')}>
      {focused ? (
        <div className={clsx(el`overlay`)} onClick={() => handleClose()}></div>
      ) : null}
      <div className={clsx('relative', el`textfield`)}>
        <TextField
          onKeyDown={handleKeyDown}
          isTransparent={p.isTransparent}
          autoFocus={p.autofocus}
          label={p.label}
          value={value}
          onChange={handleInputChange}
          validationState={undefined}
          placeholder={p.placeholder}
          onFocus={() => {
            handleSearch(p.initializeSearchWithValue ? value : '');
            setFocused(true);
          }}
          onBlur={() => setTimeout(() => setFocused(false), 300)}
          inputWrapperClassName={p.inputWrapperClassName}
          name="search-input-text-field"
          iconPath={p.iconPath}
        />
        {showDropdown ? (
          <div className={clsx(el`options`, p.optionsClassName)}>
            <div className="flex flex-row justify-end">
              {/* <div className={el`close-button`} onClick={() => handleClose()}>
                <MdClear size={14} />
                <Text type="body-xs">Close</Text>
              </div> */}
            </div>
            <>
              {showSelected && p.selectedOption ? (
                <Option
                  option={p.selectedOption}
                  selected
                  onSelect={handleClose}
                />
              ) : null}
              {p.loading && (
                <Option
                  option={{
                    label: 'Loading...',
                    details: '',
                    id: 'placeholder-id',
                  }}
                  selected={false}
                  disabled
                />
              )}
              {showNoResults && (
                <Option
                  option={{
                    label: 'No results',
                    details: '',
                    id: 'placeholder-id',
                  }}
                  selected={false}
                  disabled
                />
              )}
              {showOptions &&
                filteredOptions.map((o) => (
                  <Option
                    key={`option-${o.id}`}
                    option={o}
                    selected={false}
                    onSelect={() => {
                      p.handleSelect(o.id);
                      handleClose(o.label);
                    }}
                  />
                ))}
            </>
          </div>
        ) : null}
      </div>
    </div>
  );
};
