import { useToast } from '@shared/components/toast';
import { useReplicateOrderMutation } from '@shared/generated/graphql';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

// TODO(parlato): This is an overly ambitious name; eventually, I would
// like to refactor this context and how we handle actions on the frontend. The
// idea would be to provide a central registry context with which lower level
// components can register actions as they are rendered. This would power a
// CMD+K style interface through which a user could search for and invoke
// available actions via voice, natural language, or system generated prompts.
// It's probably best to let the dust settle on the in flight actions backend
// prior to a meaningful refactor.
type ActionRegistryContextType = {
  batchReplyJobId?: string;
  setBatchReplyJobId: Dispatch<SetStateAction<string | undefined>>;
  handleReplicate: (orderId: string) => Promise<void>;
};

export const ActionRegistryContext = createContext<ActionRegistryContextType>({
  setBatchReplyJobId: () => {},
  handleReplicate: () => Promise.resolve(),
});

export const ActionRegistryContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [batchReplyJobId, setBatchReplyJobId] = useState<string | undefined>();
  const [replicateOrder] = useReplicateOrderMutation();
  const { sendToast } = useToast();

  const handleReplicate = async (orderId: string) => {
    await replicateOrder({
      variables: { input: { id: orderId } },
      refetchQueries: ['GetJobs'],
    }).then(() => {
      sendToast('Processing has started!', { variant: 'success' });
    });
  };

  return (
    <ActionRegistryContext.Provider
      value={{
        batchReplyJobId,
        setBatchReplyJobId,
        handleReplicate,
      }}
    >
      {children}
    </ActionRegistryContext.Provider>
  );
};

export function useActionRegistryContext() {
  const context = useContext(ActionRegistryContext);
  if (!context) {
    throw new Error(
      'useActionRegistryContext must be used within a ActionRegistryContext.Provider'
    );
  }
  return context;
}
