import {
  GetQuotesDocument,
  QuoteDocument,
  RatingMethod,
  useUpdateCarrierRateMutation,
} from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { Quote } from '@shared/types/quote';
import { groupRatesByMethod } from '@shared/utils/rates/groupRatesByMethod';
import {
  getRateMapKey,
  isManualAdjustment,
  rateToLabel,
  selectNewestRatesOfEachMethod,
} from '@shared/utils/rates/rates';
import { filterRateComponents } from 'clerk_common/types';
import clsx from 'clsx';
import { useRateErrorsContext } from '../contexts/RateErrorContext';
import { useUpdateQuoteRateMutations } from '../hooks/useUpdateQuoteRateMutations';
import { GroupedRates } from './grouped-rates/GroupedRates';
import { GroupedRate } from './grouped-rates/types';
import { MarketConditionsContext } from './market-conditions/contexts/MarketConditionsContext';
import { useMarketConditionsMutation } from './market-conditions/hooks/useMarketConditionsMutation';

type SuggestedCarrierRatesProps = {
  quote: Quote;
  isDATRateViewAutomationEnabled: boolean;
  isDATLoadSearchAutomationEnabled: boolean;
  useRate: (rate: number, createNewRate: boolean) => void;
  onExpandGroupedRates: () => void;
  compact?: boolean;
};

export const SuggestedCarrierRates = (p: SuggestedCarrierRatesProps) => {
  const selectedRates = selectNewestRatesOfEachMethod(p.quote.carrierRates);
  const { handleSetQuoteCarrierRate, loading: loadingSetRate } =
    useUpdateQuoteRateMutations();
  const { rateErrors } = useRateErrorsContext();
  const [updateCarrierRate, { loading }] = useUpdateCarrierRateMutation();
  const rates: GroupedRate[] = selectedRates.map((r) => ({
    id: r.id,
    label: rateToLabel(r),
    key: getRateMapKey(r),
    ratingMethod: r.ratingMethod,
    metadata: r.metadata,
    rate:
      filterRateComponents(r.computedRate?.rateComponents)
        .filter((rc) => !isManualAdjustment(rc))
        .reduce((acc, curr) => acc + curr.value, 0) ?? 0,
  }));
  const {
    fetchMarketConditionsMutation,
    forceFetchMarketConditionsMutation,
    marketConditions,
    loading: loadingMarketConditions,
  } = useMarketConditionsMutation(p.quote.id);
  const { track } = useAnalytics();

  const groupedRates = groupRatesByMethod(rates);

  const rateErrorsWithoutGroupedRates = Object.entries(rateErrors).reduce(
    (acc, [ratingMethod, error]) => {
      if (!error || groupedRates[ratingMethod as RatingMethod]) {
        return acc;
      }

      return { ...acc, [ratingMethod]: error };
    },
    {} as Record<RatingMethod, string>
  );
  const rateMethodsWithErrors = Object.keys(rateErrorsWithoutGroupedRates);

  if (!rates.length && !rateMethodsWithErrors.length) return null;

  const onRateSelect = async (rateId: string) => {
    track('Selected Carrier Rate', { carrierRateId: rateId });
    const rates = selectNewestRatesOfEachMethod(p.quote.carrierRates);
    const rate = rates.find((r) => r.id === rateId);
    await handleSetQuoteCarrierRate(p.quote.id, rateId);
    if (rate) {
      const refetchQueries = [
        { query: QuoteDocument, variables: { id: p.quote.id } },
        GetQuotesDocument,
      ];

      // NOTE(parlato): Reset the manual adjustments when selecting a rate
      await updateCarrierRate({
        variables: {
          input: {
            id: rate.id,
            computedRate: {
              ...rate.computedRate,
              rateComponents: filterRateComponents(
                rate.computedRate?.rateComponents
              ).filter((rc) => !isManualAdjustment(rc)),
            },
          },
        },
        refetchQueries,
      });
    }
  };

  return (
    <MarketConditionsContext.Provider
      value={{
        fetchMarketConditionsMutation,
        forceFetchMarketConditionsMutation,
        marketConditions,
        loading: loadingMarketConditions,
      }}
    >
      <div className="flex flex-col gap-[12px] w-full text-gray-500 items-center">
        <div
          className={clsx(
            'flex flex-col w-full my-[8px]',
            p.compact ? 'gap-[8px]' : 'gap-[12px]'
          )}
        >
          {Object.entries(groupedRates).map(([ratingMethod, rates]) => (
            <GroupedRates
              key={`group-${ratingMethod}`}
              ratingMethod={ratingMethod as RatingMethod}
              rates={rates}
              isDisabled={loading || loadingSetRate}
              onRateSelect={onRateSelect}
              onExpandGroupedRates={p.onExpandGroupedRates}
              quote={p.quote}
              isDATRateViewAutomationEnabled={p.isDATRateViewAutomationEnabled}
              isDATLoadSearchAutomationEnabled={
                p.isDATLoadSearchAutomationEnabled
              }
            />
          ))}
        </div>
        {rateMethodsWithErrors.length > 0 &&
          rateMethodsWithErrors.map((ratingMethod) => {
            return (
              <GroupedRates
                key={`group-${ratingMethod}`}
                isDATRateViewAutomationEnabled={
                  p.isDATRateViewAutomationEnabled
                }
                isDATLoadSearchAutomationEnabled={
                  p.isDATLoadSearchAutomationEnabled
                }
                ratingMethod={ratingMethod as RatingMethod}
                onExpandGroupedRates={p.onExpandGroupedRates}
                rates={[]}
                isDisabled={false}
                onRateSelect={async (_id) => {}}
                quote={p.quote}
              />
            );
          })}
      </div>
    </MarketConditionsContext.Provider>
  );
};
