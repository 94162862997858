import { Text } from '@shared/components';
import { Tooltip } from '@shared/components/tooltip';
import { CheckSquare } from 'react-feather';
import { InteractableComponent } from '../../interactable-component';
import { el } from '../JSONDefinedForm';

type FieldLabelProps = {
  confidence: 'high' | 'low';
  label?: string;
  onMarkAsCorrect: () => void;
};
export function FieldLabel(p: FieldLabelProps) {
  return (
    <div className={el`field-label`}>
      <Text type="body-xs" isHeavy>
        {p.label}
      </Text>
      {p.confidence === 'low' && (
        <InteractableComponent
          className={el`mark-as-correct`}
          onPress={p.onMarkAsCorrect}
        >
          <div className={el`is-this-correct`}>
            <Tooltip content="Mark as correct" side="top">
              <CheckSquare size={16} />
            </Tooltip>
          </div>
        </InteractableComponent>
      )}
    </div>
  );
}
