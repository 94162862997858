import { Text } from '@shared/components/text';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { FormField } from '../../fields/FormField';
import { EditableJSONField } from '../../types';

export const ROOT = makeRootClassName('TimeEntity');
export const el = makeElementClassNameFactory(ROOT);

type TimeRangeProps = {
  startTime: EditableJSONField;
  endTime: EditableJSONField;
  prefix?: string;
};

export function TimeRange({ startTime, endTime, prefix }: TimeRangeProps) {
  return (
    <>
      <FormField
        data={startTime}
        prefix={`${prefix}_value.startTimeOrOnlyTime.`}
        className={el`element`}
      />
      <div className={el`separator`}>
        <Text className={el`element`} type="body-sm">
          to
        </Text>
      </div>
      <FormField
        data={endTime}
        prefix={`${prefix}_value.optionalEndTime.`}
        className={el`element`}
      />
    </>
  );
}
