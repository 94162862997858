import { RateForm, useGetQuoteRateFormQuery } from '@shared/generated/graphql';

export const useQuoteRateForm = (
  id: string
): { rateForm?: RateForm; loading: boolean } => {
  const { data, loading } = useGetQuoteRateFormQuery({
    variables: {
      id,
    },
  });

  return {
    rateForm: data?.quoteById?.rateForm,
    loading,
  };
};
