import { Checkbox, MoreMenu, Text, Tooltip } from '@shared/components';
import { QuoteStatusDropdownChip } from '@shared/components/quote-status-dropdown-chip';
import { useEmailThreadContext } from '@shared/contexts/pluginContexts/EmailThreadContext';
import {
  JobType,
  QuoteBidStatus,
  QuoteSummaryFragment,
  useDeleteQuoteMutation,
} from '@shared/generated/graphql';
import { ChildSelection } from '@shared/plugin/pages/Home/components/JobCard';
import { intersperseElements } from '@shared/plugin/utils/intersperseElements';
import { JobEmailMetadata } from '@shared/types/job';
import {
  formatDeliveryCityState,
  formatPickupCityState,
  getAndFormatEquipmentType,
  getCommodityFromFreightOrder,
  getWeightFromFreightOrder,
} from 'clerk_common/templates/freight_order/fieldExtractors/fieldExtractors';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { LuMoreVertical } from 'react-icons/lu';
import { PiDotOutlineFill } from 'react-icons/pi';

import { UNAVAILABLE } from '@shared/constants/placeholderText';
import { useOrganizationFeatureFlag } from '@shared/hooks/useOrganizationFeatureFlag';
import { JobIconWithColor } from '@shared/plugin/components/JobIcon';
import { formatTime } from '@shared/plugin/utils/datetime';
import { QUOTES_ROUTE } from 'clerk_common/constants/urls/routes';
import { FeatureFlagName, sumCustomerRate } from 'clerk_common/types';
import { TbArrowNarrowRight } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { useUpdateQuoteStatus } from '../hooks/useUpdateQuoteStatus';

type DecideChildIconProps = {
  selectionState?: ChildSelection;
  quoteId: string;
};
function decideChildIcon({ selectionState, quoteId }: DecideChildIconProps) {
  const defaultComponent = <PiDotOutlineFill />;
  if (isNil(selectionState)) return defaultComponent;
  if (!selectionState.showSelection) return defaultComponent;
  return (
    <ChildSelector
      isSelectable={selectionState.selectableIds.includes(quoteId)}
      isSelected={selectionState.selectedIds.includes(quoteId)}
      setIsSelected={(isSelected) => {
        if (isSelected) {
          selectionState.setSelectedIds([
            ...selectionState.selectedIds,
            quoteId,
          ]);
        } else {
          selectionState.setSelectedIds(
            selectionState.selectedIds.filter((id) => id !== quoteId)
          );
        }
      }}
    />
  );
}

type ChildSelectorProps = {
  isSelectable: boolean;
  isSelected: boolean;
  setIsSelected: (isSelected: boolean) => void;
};
function ChildSelector({
  isSelectable,
  isSelected,
  setIsSelected,
}: ChildSelectorProps) {
  const className = 'm-[1px] h-[13px] w-[13px]';

  if (!isSelectable)
    return (
      <Tooltip
        content="This item can't be included because it's missing data"
        isInstant
      >
        <Checkbox className={className} disabled />
      </Tooltip>
    );
  return (
    <Checkbox
      className={className}
      checked={isSelected}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onCheckedChange={() => {
        setIsSelected?.(!isSelected);
      }}
    />
  );
}

interface QuoteRowProps {
  quote: QuoteSummaryFragment;
  asJobChild?: boolean;
  siblingSelectionState?: ChildSelection;
}

export const QuoteRow = ({
  quote,
  asJobChild,
  siblingSelectionState,
}: QuoteRowProps) => {
  const { thisItem, openThread } = useEmailThreadContext();
  const { updateQuoteStatus, loading } = useUpdateQuoteStatus();
  const [deleteQuote, { loading: deleteLoading }] = useDeleteQuoteMutation();
  const showFeedView = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.SHOW_THREAD_MESSAGE_CLASSIFICATIONS,
  });

  const navigate = useNavigate();
  const emailMetadata = quote.job.emailMetadata as JobEmailMetadata;
  const hoverClass = 'hover:shadow-sm cursor-pointer hover:bg-brand-50';
  const paddingClass = asJobChild ? 'pl-8 py-2' : 'p-2';
  const isDifferentThread =
    emailMetadata.itemId && thisItem?.id !== emailMetadata.itemId;
  const createdAtDisplay = new Date(quote.createdAtDisplay);
  const pickup = formatPickupCityState(quote.extractedData) ?? UNAVAILABLE;
  const delivery = formatDeliveryCityState(quote.extractedData) ?? UNAVAILABLE;
  const equipmentType = getAndFormatEquipmentType(quote.extractedData);
  const commodity = getCommodityFromFreightOrder(quote.extractedData);
  const weight = getWeightFromFreightOrder(quote.extractedData);
  const isNew = quote.status === QuoteBidStatus.NEW;
  const rate = quote.customerRate ?? undefined;
  const sellRate = sumCustomerRate(rate);

  const getQuoteDetails = () => {
    const elements = [equipmentType, commodity, weight].filter(
      (el) => el !== UNAVAILABLE && typeof el === 'string' && el.trim()
    );
    return intersperseElements(elements);
  };

  const handleDelete = async () => {
    await deleteQuote({
      variables: { input: { id: quote.id } },
      refetchQueries: ['GetQuoteSummaries', 'GetQuotes'],
    });
  };

  const moreMenuLoading = deleteLoading;
  const moreMenuOptions = [
    ...(isDifferentThread
      ? [
          {
            label: 'Open email thread',
            onPress: () => openThread(emailMetadata),
          },
        ]
      : []),
    { label: 'Delete', onPress: handleDelete, isDestructive: true },
  ];

  return (
    <div
      onClick={
        siblingSelectionState?.showSelection
          ? undefined
          : () => navigate(`${QUOTES_ROUTE}/${quote.id}`)
      }
      className={clsx(
        'flex flex-row gap-2 w-full bg-white cursor-pointer border-gray-200 justify-between',
        hoverClass,
        paddingClass
      )}
    >
      <div className="text-gray-700">
        {asJobChild ? (
          decideChildIcon({
            selectionState: siblingSelectionState,
            quoteId: quote.id,
          })
        ) : (
          <JobIconWithColor jobType={JobType.QUOTES} />
        )}
      </div>
      <div className="flex flex-col w-full">
        {!asJobChild && (
          <div className="flex flex-row w-full items-center justify-between">
            <Text type="body-xs" className="line-clamp-1 text-gray-700" isHeavy>
              {quote.originator?.name ?? 'Unknown customer'}
            </Text>
            <div className="flex flex-row items-center gap-1">
              {!showFeedView && (
                <Text
                  type="body-xs"
                  className="text-gray-500 text-right line-clamp-1"
                >
                  {formatTime(createdAtDisplay)}
                </Text>
              )}
              <MoreMenu
                moreIcon={<LuMoreVertical size={12} />}
                options={moreMenuOptions}
                isLoading={moreMenuLoading}
              />
            </div>
          </div>
        )}
        <div className="flex flex-row gap-[2px] text-gray-700">
          <Text
            type="body-xs"
            isHeavy={isNew}
            className="break-words line-clamp-1"
          >
            {pickup || 'Unknown'}
          </Text>
          <TbArrowNarrowRight />
          <Text
            type="body-xs"
            isHeavy={isNew}
            className="break-words line-clamp-1"
          >
            {delivery || 'Unknown'}
          </Text>
        </div>
        <div className="flex flex-row items-center gap-1">
          {getQuoteDetails()}
        </div>
        <div className="mt-1 w-min" onClick={(e) => e.stopPropagation()}>
          <QuoteStatusDropdownChip
            status={quote.status}
            sellRate={sellRate}
            handleSelect={(value) => updateQuoteStatus(quote.id, value)}
            isDisabled={loading}
          />
        </div>
      </div>
    </div>
  );
};
