import useDraftQuoteResponse from '@shared/components/draft-response/hooks/useDraftQuoteResponse';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { createContext, useCallback } from 'react';
import { useEmailThreadContext } from './pluginContexts/EmailThreadContext';

export type ResponseDraftContextType = {
  draft?: string;
  draftIsLoading?: boolean;

  draftReply?: () => void;
  draftReplyInProgress?: boolean;

  composeMessage?: () => void;
  composeMessageInProgress?: boolean;

  trackingQuoteId?: string;
  batchCount?: number;
};

export const ResponseDraftContext = createContext<ResponseDraftContextType>({
  draft: undefined,
  draftIsLoading: false,
  draftReply: undefined,
  draftReplyInProgress: false,
  composeMessage: undefined,
  composeMessageInProgress: false,
  trackingQuoteId: undefined,
  batchCount: undefined,
});

export const ResponseDraftContextProvider = ({
  quoteIds,
  itemId,
  respondToEmail,
  children,
}: {
  quoteIds: string[];
  itemId: string;
  respondToEmail?: string;
  children: React.ReactNode;
}) => {
  const { reply } = useEmailThreadContext();
  const { draftReplyAll, loadingDraftReplyAll: draftReplyInProgress } = reply;
  const { track } = useAnalytics();
  const { message: draft, isLoading: draftIsLoading } = useDraftQuoteResponse({
    quoteIds,
  });

  // NOTE(max): The API only supports associating one quote
  // with the tracking event for now.
  const trackingQuoteId = quoteIds[0];
  const batchCount = quoteIds.length;
  const draftReply = useCallback(async () => {
    track('Clicked Draft Reply All', {
      quoteId: trackingQuoteId,
      count: batchCount,
    });
    await draftReplyAll(itemId, draft);
  }, [itemId, draftReplyAll, quoteIds, draft, trackingQuoteId, batchCount]);

  const composeEmail = useCallback(() => {
    if (!draft) {
      console.error('No draft');
      return;
    }

    if (!respondToEmail) {
      console.error('No respondToEmail');
      return;
    }

    track('Clicked Compose Email', {
      quoteId: trackingQuoteId,
      count: batchCount,
    });
    window.open(
      `mailto:${respondToEmail}?subject=${encodeURI(
        'Re: Upcoming Shipment'
      )}&body=${encodeURI(draft)}`
    );
  }, [respondToEmail, draft, quoteIds, trackingQuoteId, batchCount]);

  return (
    <ResponseDraftContext.Provider
      value={{
        draft,
        draftIsLoading,
        draftReply,
        draftReplyInProgress,
        // NOTE(max): In the future we may want to support other message types.
        composeMessage: composeEmail,
        composeMessageInProgress: false,
        trackingQuoteId,
        batchCount,
      }}
    >
      {children}
    </ResponseDraftContext.Provider>
  );
};
