import { Button, CircularProgress } from '@shared/components';
import { useFeedContext } from '@shared/plugin/contexts/FeedContext';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { ReactNode, useMemo, useState } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import {
  PiArrowBendDoubleUpLeftBold,
  PiArrowBendUpLeftBold,
  PiCurrencyDollarBold,
  PiFilePlusBold,
  PiTextOutdent,
  PiTrayBold,
} from 'react-icons/pi';

import clsx from 'clsx';
import {
  SuggestedAction,
  SuggestedActionsFeedItem,
  SuggestedActionType,
} from '../../types';

const ROOT = makeRootClassName('SuggestedActions');
const el = makeElementClassNameFactory(ROOT);

type SuggestedActionItemProps = {
  item: SuggestedActionsFeedItem;
  isCompact?: boolean;
};

const ActionChip = ({
  content,
  icon,
  handleClick,
  loading,
  isCompact,
  isFirst,
}: {
  content: string;
  icon: ReactNode;
  handleClick: () => void;
  loading: boolean;
  isFirst: boolean;
  isCompact?: boolean;
}) => {
  return (
    <Button
      variant={isCompact && isFirst ? 'brand' : 'secondary'}
      size={isCompact ? 'xs' : 'small'}
      icon={loading ? undefined : icon}
      loadingIndicator={
        loading ? (
          <CircularProgress size="2xs" className={el`action-loading`} />
        ) : undefined
      }
      onPress={handleClick}
      isDisabled={loading}
      className={isCompact ? undefined : el`action-button`}
    >
      {content}
    </Button>
  );
};

const ActionChipContainer = ({
  content,
  icon,
  action,
  isCompact,
  isFirst,
}: {
  content: string;
  icon: ReactNode;
  action: SuggestedAction;
  isFirst: boolean;
  isCompact?: boolean;
}) => {
  const {
    feed: { executeAction },
  } = useFeedContext();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    executeAction(action).finally(() => {
      setLoading(false);
    });
  };

  return (
    <ActionChip
      isFirst={isFirst}
      isCompact={isCompact}
      content={content}
      icon={icon}
      handleClick={handleClick}
      loading={loading}
    />
  );
};

const DEFAULT_ACTIONS_COUNT = 3;

export const SuggestedActionsItem = ({
  item,
  isCompact,
}: SuggestedActionItemProps) => {
  const [showAllSuggestions, setShowAllSuggestions] = useState(false);
  const renderAction = (action: SuggestedAction, idx: number) => {
    const isFirst = idx === 0;
    switch (action.type) {
      case SuggestedActionType.BUILD_ORDER:
        return (
          <ActionChipContainer
            action={action}
            isCompact={isCompact}
            isFirst={isFirst}
            content="Build order"
            icon={
              <PiTrayBold
                size={14}
                className={clsx(el`action-icon`, 'text-brand-600')}
              />
            }
          />
        );
      case SuggestedActionType.BUILD_QUOTE:
        return (
          <ActionChipContainer
            action={action}
            isCompact={isCompact}
            isFirst={isFirst}
            content="Create quote"
            icon={
              <PiCurrencyDollarBold
                size={14}
                className={clsx(el`action-icon`, 'text-orange-600')}
              />
            }
          />
        );
      case SuggestedActionType.BUILD_REMAINING_ORDERS:
        return (
          <ActionChipContainer
            action={action}
            isCompact={isCompact}
            isFirst={isFirst}
            content="Build remaining loads"
            icon={
              <PiFilePlusBold size={14} className={clsx(el`action-icon`)} />
            }
          />
        );
      case SuggestedActionType.DRAFT_QUOTE_REPLY:
        return (
          <ActionChipContainer
            action={action}
            isCompact={isCompact}
            isFirst={isFirst}
            content="Draft quote reply"
            icon={
              <PiArrowBendUpLeftBold
                size={14}
                className={clsx(el`action-icon`)}
              />
            }
          />
        );
      case SuggestedActionType.BATCH_QUOTE_REPLY:
        return (
          <ActionChipContainer
            action={action}
            isCompact={isCompact}
            isFirst={isFirst}
            content="Batch reply"
            icon={
              <PiArrowBendDoubleUpLeftBold
                size={14}
                className={clsx(el`action-icon`)}
              />
            }
          />
        );
      case SuggestedActionType.OPEN_LINK:
        return (
          <ActionChipContainer
            action={action}
            isCompact={isCompact}
            isFirst={isFirst}
            content={action.title}
            icon={<MdOpenInNew size={14} className={clsx(el`action-icon`)} />}
          />
        );
      case SuggestedActionType.MARK_QUOTE_QUOTED:
        return (
          <ActionChipContainer
            action={action}
            isCompact={isCompact}
            isFirst={isFirst}
            content={'Mark quote quoted'}
            icon={<PiTextOutdent size={14} className={clsx(el`action-icon`)} />}
          />
        );
      case SuggestedActionType.MARK_QUOTE_WON:
        return (
          <ActionChipContainer
            action={action}
            isCompact={isCompact}
            isFirst={isFirst}
            content={'Mark quote won'}
            icon={
              <PiTextOutdent
                size={14}
                className={clsx(el`action-icon`, 'text-green-600')}
              />
            }
          />
        );
      default:
        throw new Error(`Unknown action: ${(action as any).type}`);
    }
  };

  const actions = useMemo(() => {
    if (showAllSuggestions) {
      return item.data.actions;
    }

    return item.data.actions.slice(0, DEFAULT_ACTIONS_COUNT);
  }, [item.data.actions, showAllSuggestions]);

  return (
    <div className={el`suggested-actions-root`}>
      {actions.map((action, idx) => {
        return (
          <div key={`suggested-action-${idx}`} className={el`action-container`}>
            {renderAction(action, idx)}
          </div>
        );
      })}
      {item.data.actions.length > DEFAULT_ACTIONS_COUNT && (
        <Button
          variant="tertiary"
          size="small"
          onPress={() => setShowAllSuggestions((c) => !c)}
          className={el`show-more-button`}
        >
          {showAllSuggestions ? 'Less' : 'More...'}
        </Button>
      )}
    </div>
  );
};
