import { Text } from '@shared/components';
import clsx from 'clsx';

import { CircularProgress } from './CircularProgress';

interface ThreadActionProps {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
}

export const ThreadAction = ({
  title,
  icon,
  onClick,
  loading,
  disabled,
}: ThreadActionProps) => {
  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={clsx(
        'border rounded-md border-gray-200 px-4 mx-1 py-4 bg-white flex-1',
        disabled
          ? 'cursor-not-allowed'
          : 'hover:shadow-sm cursor-pointer hover:border-brand-400 hover:bg-brand-50'
      )}
    >
      <div className="flex flex-col items-center m-2 gap-1">
        {loading ? (
          <CircularProgress size="md" />
        ) : (
          <div className={disabled ? 'text-gray-300' : 'text-brand-400'}>
            {icon}
          </div>
        )}
        <div
          className={clsx(
            'text-body-xs-heavy text-center text-gray-700',
            disabled && 'text-gray-500'
          )}
        >
          <Text type="body-xs" isHeavy>
            {title}
          </Text>
        </div>
      </div>
    </div>
  );
};
