// TODO(mike): I've moved these params here so that they can be shared, but
// there's still a lot of cleanup to be done. We have at least two different
// case conventions below. And I think having multiple enums means that there's
// no protection against url param conflicts.

/**
Tracks the names of arguments in the query string that are
added as query params (e.g. /jobs?jobId=123&tab=details)
Since query params are global state, setting arguments and fetching
arguments using these enum values helps avoid conflicts.
NOTE: if a query param name conflicts with a Next dynamic route
param name, and both are matched on the url, the dynamic route param
will take precedence.
*/
export enum QueryParamName {
  DRAWER_TAB = 'tab',
  JOB_ID = 'jobId',
  ORDER_ID = 'orderId',
  ORGANIZATION_ID = 'organizationId',
  ORIGINATOR_ID = 'originatorId',
  PLACE_ID = 'placeId',
  USER_ID = 'userId',
  ORDER_MODAL_IS_OPEN = 'orderModalIsOpen',
  SEARCH_TERM = 'search-term',
  MEMBER_ID = 'memberId',
  QUOTE_ID = 'quoteId',
  ONBOARDING_FORM_IS_OPEN = 'onboardingFormIsOpen',
  LABELING_ENTITY_ID = 'labelingEntityId',
  LABELING_ENTITY_TYPE = 'labelingEntityType',
  ORIGIN_PLACE_ID = 'originPlaceId',
  DESTINATION_PLACE_ID = 'destinationPlaceId',
  COMPUTATION_TYPE = 'computationType',
  COMPUTATION_ID = 'computationId',
  DOCS_ENTITY_ID = 'docsEntityId',
  DOCS_ENTITY_TYPE = 'docsEntityType',
  INSIGHTS_DASHBOARD = 'insightsDashboard',
  DRAFT_REPLY = 'draftReply',
}

export enum DrawerKind {
  JOB = 'JOB',
  ORIGINATOR = 'ORIGINATOR',
  ORGANIZATION = 'ORGANIZATION',
  PLACE = 'PLACE',
  MEMBER = 'MEMBER',
  QUOTE = 'QUOTE',
  USER = 'USER',
}

export enum DrawerTabValue {
  DETAILS = 'details',
  RATES = 'rates',
  DOCUMENTS = 'documents',
  TEMPLATES = 'templates',
  SENDERS = 'senders',
  INTEGRATIONS = 'integrations',
  MEMBERS = 'members',
  EDITOR = 'editor',
  WORKFLOWS = 'workflows',
  AUDIT_TRAIL = 'audit-trail',
  INBOX_AUTOMATION = 'inbox-automation',
}

export enum DropdownQueryParam {
  ORGANIZATION = 'organization',
  ORIGINATOR_ID = 'originator-id',
  ORIGINATOR_STATUS = 'originator-status',
  PLACE_ID = 'place-id',
  USER_ID = 'user-id',
  JOB_STATUS = 'job-status',
  QUOTE_STATUS = 'quote-status',
  PLACE_TAG = 'place-tag',
  PICKUP_LOCATION = 'pickup-location',
  DELIVERY_LOCATION = 'delivery-location',
  CREATED_AT_START_DATE = 'created-at-start-date',
  CREATED_AT_END_DATE = 'created-at-end-date',
  PICKUP_FIRST_DATE = 'pickup-first-date',
  PICKUP_SECOND_DATE = 'pickup-second-date',
  DELIVERY_FIRST_DATE = 'delivery-first-date',
  DELIVERY_SECOND_DATE = 'delivery-second-date',
  CUSTOM_MIN_VALUE = 'custom-min-value',
  CUSTOM_MAX_VALUE = 'custom-max-value',
  SUBMITTED = 'submitted',
  ORIGIN_PLACE_ID = 'origin-place-id',
  DESTINATION_PLACE_ID = 'destination-place-id',
  COMPUTATION_TYPE = 'computation-type',
  EQUIPMENT_TYPE = 'equipment-type',
  MEMBER_ROLE = 'member-role',
  FREIGHT_MODE = 'freight-mode',
  LABEL_STATUS = 'label-status',
}
