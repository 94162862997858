export const JOBS_ROUTE = '/jobs';
export const ORDERS_ROUTE = '/orders';
export const ORIGINATORS_ROUTE = '/originators';
export const ORGANIZATIONS_ROUTE = '/organizations';
export const ORGANIZATION_SETTINGS_ROUTE = '/organizationSettings';
export const PLACES_ROUTE = '/places';
export const QUOTES_ROUTE = '/quotes';
export const USERS_ROUTE = '/users';
export const LABELS_ROUTE = '/labels';
export const INSIGHTS_ROUTE = '/insights';
export const FEATURE_FLAGS_ROUTE = '/featureFlags';

/**
 * Routes accessible to different hierarchical roles
 */
export const USER_LEVEL_ROUTES = [ORDERS_ROUTE, QUOTES_ROUTE];
export const SUPPORT_LEVEL_ROUTES = [
  ...USER_LEVEL_ROUTES,
  JOBS_ROUTE,
  ORIGINATORS_ROUTE,
  LABELS_ROUTE,
];
export const ADMIN_LEVEL_ROUTES = [
  ...SUPPORT_LEVEL_ROUTES,
  ORGANIZATIONS_ROUTE,
  PLACES_ROUTE,
  USERS_ROUTE,
];
