export enum PricingStrategyType {
  SIMPLE_MARGIN = 'SIMPLE_MARGIN',
}

export enum RateComponentType {
  LINE_HAUL = 'LINE_HAUL',
  FUEL_SURCHARGE = 'FUEL_SURCHARGE',
  MANUAL_ADJUSTMENT = 'MANUAL_ADJUSTMENT',
  MARGIN = 'MARGIN',
  TOTAL = 'TOTAL',
  OTHER = 'OTHER',
}

export enum RatingMethod {
  'ARCBEST_TL_QUOTING' = 'ARCBEST_TL_QUOTING',
  'DAT' = 'DAT',
  'GREENSCREENS' = 'GREENSCREENS',
  'HUMAN_INPUT' = 'HUMAN_INPUT',
  'CUSTOM' = 'CUSTOM',
  'SUNSET' = 'SUNSET',
  'NFI' = 'NFI',
  'TABI' = 'TABI',
}

export type SimpleMarginConfig = {
  ratingMethod: RatingMethod;
  marginPercentage?: number;
  markupDollars?: number;
  minimumMargin?: number;
};

export type PricingStrategyConfiguration = SimpleMarginConfig;
