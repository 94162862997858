import { Tooltip } from '@shared/components/tooltip';
import { RatingMethod } from '@shared/generated/graphql';

export const RateLogo = ({ rateMethod }: { rateMethod?: RatingMethod }) => {
  if (!rateMethod) return null;

  const gsTooltip = 'Rate sourced from Greenscreens.ai';
  const datTooltip = 'Rate sourced from DAT';
  const sunsetTooltip = 'Rate sourced from Sunset';
  const tabiTooltip = 'Rate sourced from Tabi';

  switch (rateMethod) {
    case RatingMethod.DAT:
      return (
        <Tooltip content={datTooltip} isInstant>
          <img width={48} src="/dat.png" alt={datTooltip} />
        </Tooltip>
      );
    case RatingMethod.GREENSCREENS:
      return (
        <Tooltip content={gsTooltip} isInstant>
          <img width={32} src="/greenscreens.png" alt={gsTooltip} />
        </Tooltip>
      );
    case RatingMethod.SUNSET:
      return (
        <Tooltip content={sunsetTooltip} isInstant>
          <div className="border rounded-md p-[2px]">
            <img width={32} src="/sunset.png" alt={sunsetTooltip} />
          </div>
        </Tooltip>
      );
    case RatingMethod.TABI:
      return (
        <Tooltip content={tabiTooltip} isInstant>
          <div className="border rounded-md p-[2px]">
            <img width={32} src="/tabi.png" alt={tabiTooltip} />
          </div>
        </Tooltip>
      );
    default:
      return null;
  }
};
