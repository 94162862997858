import { Text, Tooltip } from '@shared/components';
import QuoteStatusDropdownChip from '@shared/components/quote-status-dropdown-chip/QuoteStatusDropdownChip';
import {
  QuoteBidStatus,
  QuoteHistorySummaryFragment,
} from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { formatTime } from '@shared/plugin/utils/datetime';
import { getElementSeparator } from '@shared/plugin/utils/intersperseElements';
import { makeRootClassName } from '@shared/utils';
import { sumCustomerRate } from 'clerk_common/types';
import clsx from 'clsx';
import { useState } from 'react';
import { LuMinus, LuPlus } from 'react-icons/lu';

interface QuoteHistoryRowProps {
  quote: QuoteHistorySummaryFragment;
  navigateToQuote: (quoteId: string) => void;
}

const ROOT = makeRootClassName('QuoteHistoryRow');

const QuoteHistoryRow = (p: QuoteHistoryRowProps) => {
  const sellRate = p.quote.customerRate;
  const createdAt = new Date(p.quote.createdAt);
  const rate = sellRate ? sumCustomerRate(sellRate) : null;
  const { track } = useAnalytics();

  if (!rate) return null;

  const statusChip = (status: QuoteBidStatus) => {
    return (
      <div className={clsx(ROOT)}>
        <QuoteStatusDropdownChip status={status} sellRate={rate} />
      </div>
    );
  };

  return (
    <div
      className="flex flex-col gap-[4px] items-start cursor-pointer"
      onClick={() => {
        track('Clicked Lane History Quote', { quoteId: p.quote.id });
        p.navigateToQuote(p.quote.id);
      }}
    >
      <div className="flex flex-row gap-[4px] items-center text-gray-600">
        {statusChip(p.quote.status)}
        {getElementSeparator({ className: 'text-gray-600' })}
        <Text type="body-sm">{formatTime(createdAt)}</Text>
      </div>
    </div>
  );
};

interface QuoteLaneHistoryProps {
  quotes: QuoteHistorySummaryFragment[];
  quoteId: string;
  navigateToQuote: (quoteId: string) => void;
}

export const QuoteLaneHistory = (p: QuoteLaneHistoryProps) => {
  const [expanded, setExpanded] = useState(true);
  const { track } = useAnalytics();

  const handleToggleHistory = () => {
    const event = expanded ? 'Collapse Lane History' : 'Expand Lane History';
    setExpanded(!expanded);
    track(event, {
      quoteId: p.quoteId,
    });
  };

  return p.quotes.length ? (
    <div className="p-4 flex flex-col">
      <>
        <div
          onClick={handleToggleHistory}
          className="flex flex-row justify-between items-center cursor-pointer text-gray-600"
        >
          <Text type="body-md">Lane quote history</Text>
          <Tooltip
            isInstant
            content={expanded ? 'Collapse lane history' : 'Expand lane history'}
          >
            {expanded ? <LuMinus size={16} /> : <LuPlus size={16} />}
          </Tooltip>
        </div>
        {expanded && (
          <div className="flex flex-col gap-2 mt-2 cursor-pointer">
            <Text type="body-xs" className="text-gray-500">
              Recent quotes for this customer with the same equipment type and
              similar origin-destination pair (50 mile radius)
            </Text>
            {p.quotes.map((q) => (
              <QuoteHistoryRow
                key={`history-${q.id}`}
                quote={q}
                navigateToQuote={p.navigateToQuote}
              />
            ))}
          </div>
        )}
      </>
    </div>
  ) : null;
};
