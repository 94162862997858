import { CircularProgress, Text } from '@shared/components';
import { ThreadAction } from '@shared/components/plugin-components';
import { useToast } from '@shared/components/toast';
import { useIntercomSupportContext } from '@shared/contexts/hooks/useIntercomSupportContext';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useAuthorizedIntegrationContext } from '@shared/contexts/pluginContexts/AuthorizedIntegrationContext';
import { useEmailThreadContext } from '@shared/contexts/pluginContexts/EmailThreadContext';
import { useAuthContext } from '@shared/contexts/useAuthContext';
import { JobType } from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { useEmailScanningCue } from '@shared/hooks/useEmailScanningCue';
import { useOrganizationFeatureFlag } from '@shared/hooks/useOrganizationFeatureFlag';
import { ActionRegistryContextProvider } from '@shared/plugin/contexts/ActionRegistryContext';
import { FeedContextProvider } from '@shared/plugin/contexts/FeedContext';
import { Feed } from '@shared/plugin/features/Feed';
import { FeedFilter } from '@shared/plugin/features/Feed/components/feed-filter/FeedFilter';
import { shouldPromptToAllowScanning } from 'clerk_common/features/emailScanning';
import { FeatureFlagName } from 'clerk_common/types';
import clsx from 'clsx';
import { useEffect } from 'react';
import { PiCurrencyDollarBold, PiTrayBold } from 'react-icons/pi';
import { EmailThreadActivity } from './components/email-thread-activity';
import { JobCard } from './components/JobCard';
import { SharedInboxNotice } from './components/SharedInboxNotice';

const Home = () => {
  const { isAuthenticated } = useAuthContext();
  const { threadJobs, createOrder, createQuote, thisItem } =
    useEmailThreadContext();
  const { me, loading, hasOrders, hasQuotes, hasEmailScanning, defaultOrgId } =
    useMeContext();
  const {
    authorized,
    loading: loadingAuthorizedIntegration,
    renderAuthInstructions,
  } = useAuthorizedIntegrationContext();
  const { sendToast } = useToast();
  const { page, track } = useAnalytics();
  const { handleAddAllowedSender } = useEmailScanningCue();
  useIntercomSupportContext();
  const showFeedView = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.SHOW_THREAD_MESSAGE_CLASSIFICATIONS,
  });

  useEffect(() => {
    if (me?.id) {
      page('Home');
    }
  }, [me?.id]);

  useEffect(() => {
    if (createOrder.loading || createQuote.loading) return;

    const error = createOrder.error || createQuote.error;
    if (error) {
      sendToast(error.message, {
        variant: 'error',
        compact: true,
      });
    }
  }, [createOrder.error, createQuote.error]);

  const createJob = async (jobType: JobType) => {
    switch (jobType) {
      case JobType.ORDERS:
        track('Clicked Build Order', {});
        return createOrder.call();
      case JobType.QUOTES:
        track('Clicked Create Quote', {});
        return createQuote.call();
      default:
        throw new Error(`Invalid job type: ${jobType}`);
    }
  };

  const isLoggedIn = me?.email && isAuthenticated;
  const showTransactions = authorized && !loading && isLoggedIn;

  const disableTransactions = Boolean(showTransactions && !thisItem?.id);
  const shouldAuthorize =
    !loading && isLoggedIn && !authorized && !loadingAuthorizedIntegration;

  const handleSendToVooma = async (jobType: JobType) => {
    const job = await createJob(jobType);

    if (job) threadJobs.storeJobType(jobType, job?.id);

    const contactEmail = thisItem?.sender.emailAddress;
    if (job && contactEmail && defaultOrgId && hasEmailScanning) {
      const shouldAddSender = shouldPromptToAllowScanning(
        contactEmail,
        job.source,
        defaultOrgId,
        me?.features
      );
      if (shouldAddSender) {
        const shouldToast = false;
        handleAddAllowedSender(contactEmail, shouldToast);
        sendToast(`Your ${jobType.toLowerCase().slice(0, -1)} is processing.`, {
          description: `Future ${jobType.toLowerCase()} from this sender will be available instantly.`,
          autoHideDuration: 3000,
          isDismissible: true,
        });
      }
    }
  };

  const emptyState = `No ${
    hasQuotes && hasOrders
      ? 'quotes or orders'
      : hasQuotes
      ? 'quotes'
      : 'orders'
  } have been created for this email thread.`;

  return (
    <ActionRegistryContextProvider>
      <FeedContextProvider
        handleSendToVooma={handleSendToVooma}
        disableTransactions={disableTransactions}
      >
        <div className="flex flex-col bg-gray-50 h-full">
          <div
            className={clsx(
              'drop-shadow bg-white',
              showFeedView ? 'pb-2' : 'min-h-[1px]'
            )}
          >
            {showFeedView && <FeedFilter />}
          </div>
          <SharedInboxNotice />
          <ul
            className="flex flex-col gap-y-[2px]"
            style={{ listStyle: 'none' }}
          >
            {loading && (
              <div className="flex flex-col w-full gap-4 items-center justify-center h-48">
                <CircularProgress size="small" />
              </div>
            )}
            {shouldAuthorize && renderAuthInstructions()}
            {showFeedView && <EmailThreadActivity />}
            {!showFeedView && showTransactions && (
              <>
                <div className="flex justify-center px-1 my-2">
                  {hasQuotes ? (
                    <ThreadAction
                      title={
                        createQuote.loading
                          ? 'Creating quote...'
                          : 'Create quote'
                      }
                      icon={<PiCurrencyDollarBold size={24} />}
                      onClick={() => handleSendToVooma(JobType.QUOTES)}
                      loading={createQuote.loading}
                      disabled={disableTransactions}
                    />
                  ) : null}

                  {hasOrders ? (
                    <ThreadAction
                      title={
                        createOrder.loading
                          ? 'Building order...'
                          : 'Build order'
                      }
                      icon={<PiTrayBold size={24} />}
                      onClick={() => handleSendToVooma(JobType.ORDERS)}
                      loading={createOrder.loading}
                      disabled={disableTransactions}
                    />
                  ) : null}
                </div>
              </>
            )}
          </ul>
          <div className="flex flex-col overflow-y-scroll no-scrollbar">
            {!showFeedView && threadJobs.jobs.length > 0
              ? threadJobs.jobs.map((job) => {
                  const jobType = threadJobs.jobTypes[job.id];
                  return (
                    <JobCard key={job.id} job={job} initialJobType={jobType} />
                  );
                })
              : null}
            {threadJobs.jobs.length === 0 &&
              !threadJobs.loading &&
              isAuthenticated && (
                <div className="flex justify-center mt-32 px-10 text-center">
                  <Text type="body-sm" className="text-gray-400">
                    {emptyState}
                  </Text>
                </div>
              )}
          </div>
          {showFeedView && showTransactions && <Feed />}
        </div>
      </FeedContextProvider>
    </ActionRegistryContextProvider>
  );
};

export default Home;
