import { Text } from '@shared/components';
import { JobType } from '@shared/generated/graphql';
import { JobIcon } from '@shared/plugin/components/JobIcon';
import { useFeedContext } from '@shared/plugin/contexts/FeedContext';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { enumToString } from 'clerk_common/enums';
import clsx from 'clsx';
import { useMemo } from 'react';
import { FeedFilterOption, FeedItemType } from '../../types';

const ROOT = makeRootClassName('FeedFilter');
const el = makeElementClassNameFactory(ROOT);

export const FeedFilter = () => {
  const {
    feed: { items },
    filter,
  } = useFeedContext();

  const { selectedOption, setSelectedOption, filterOptions } = filter;

  const quoteCount = useMemo(() => {
    return items.reduce((acc, item) => {
      if (item.type === FeedItemType.JOB_CARD) {
        return acc + item.data.job.quotes.length;
      }
      return acc;
    }, 0);
  }, [items]);

  const orderCount = useMemo(() => {
    return items.reduce((acc, item) => {
      if (item.type === FeedItemType.JOB_CARD) {
        return acc + item.data.job.orders.length;
      }
      return acc;
    }, 0);
  }, [items]);

  const renderIcon = (option: FeedFilterOption) => {
    switch (option) {
      case FeedFilterOption.QUOTES:
        return <JobIcon jobType={JobType.QUOTES} />;
      case FeedFilterOption.ORDERS:
        return <JobIcon jobType={JobType.ORDERS} />;
      case FeedFilterOption.ACTIVITY:
      default:
        return null;
    }
  };

  const getIsDisabled = (option: FeedFilterOption) => {
    switch (option) {
      case FeedFilterOption.QUOTES:
        return quoteCount === 0;
      case FeedFilterOption.ORDERS:
        return orderCount === 0;
      default:
        return false;
    }
  };

  return (
    <div className={el`feed-filter-root`}>
      {filterOptions.map((option) => {
        const isSelected = selectedOption === option;
        const isDisabled = getIsDisabled(option);
        return (
          <div
            key={option}
            className={clsx(
              el`option`,
              isSelected && 'selected',
              isDisabled && 'disabled'
            )}
            onClick={isDisabled ? undefined : () => setSelectedOption(option)}
          >
            {renderIcon(option)}
            <Text type="body-xs">{enumToString(option)}</Text>
          </div>
        );
      })}
    </div>
  );
};
