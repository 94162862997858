import { Button, MoreMenu, OrderStatusChip, Text } from '@shared/components';
import { useToast } from '@shared/components/toast';
import { UNAVAILABLE } from '@shared/constants/placeholderText';
import {
  JobType,
  OrderSummaryFragment,
  useDeleteOrderMutation,
  useGetHasExtractedDataConfiguredQuery,
  useReplicateOrderMutation,
} from '@shared/generated/graphql';
import useInitializeTemplate from '@shared/graphql/hooks/templates/useInitializeTemplate';
import { useOrganizationFeatureFlag } from '@shared/hooks/useOrganizationFeatureFlag';
import { JobIconWithColor } from '@shared/plugin/components/JobIcon';
import { formatTime } from '@shared/plugin/utils/datetime';
import { getElementSeparator } from '@shared/plugin/utils/intersperseElements';
import { ORDERS_ROUTE } from 'clerk_common/constants/urls/routes';
import {
  formatDeliveryCityState,
  formatPickupCityState,
  getAndFormatEquipmentType,
  getCommodityFromFreightOrder,
  getWeightFromFreightOrder,
} from 'clerk_common/templates/freight_order/fieldExtractors/fieldExtractors';
import { CompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { FeatureFlagName } from 'clerk_common/types';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { LuMoreVertical } from 'react-icons/lu';
import { PiDotOutlineFill } from 'react-icons/pi';
import { TbArrowNarrowRight } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { OriginatorOnboardingType } from '../../OriginatorOnboarding/components/OriginatorOnboardingWizard/types';
import { getOnboardingRoute } from '../../OriginatorOnboarding/OriginatorOnboarding';
import { ChildSelection } from './JobCard';

interface OrderRowProps {
  order: OrderSummaryFragment;
  asJobChild?: boolean;
  siblingSelectionState?: ChildSelection;
  showReplicatePrompt?: boolean;
}

interface OrderRowDetailsProps {
  data: CompletedFreightOrderTemplate;
}

export const OrderRowDetails = ({ data }: OrderRowDetailsProps) => {
  if (!data._value) return null;

  const pickup = formatPickupCityState(data) ?? UNAVAILABLE;
  const delivery = formatDeliveryCityState(data) ?? UNAVAILABLE;
  const equipmentType = getAndFormatEquipmentType(data);
  const commodity = getCommodityFromFreightOrder(data);
  const weight = getWeightFromFreightOrder(data);

  const getOrderDetails = () => {
    const elements = [equipmentType, commodity, weight].filter(
      (e) => typeof e === 'string' && e.trim()
    );
    const interspersedElements: ReactNode[] = [];
    elements
      .filter((el) => el !== UNAVAILABLE)
      .forEach((element, index) => {
        interspersedElements.push(
          <Text
            key={index}
            type="body-xs"
            className="text-gray-500 break-words line-clamp-1"
          >
            {element}
          </Text>
        );
        if (index < elements.length - 1) {
          interspersedElements.push(getElementSeparator());
        }
      });
    return interspersedElements;
  };

  return (
    <>
      <div className="flex flex-row gap-[2px] text-gray-700">
        <Text type="body-xs" isHeavy className="break-words line-clamp-1">
          {pickup || 'Unknown'}
        </Text>
        <TbArrowNarrowRight />
        <Text type="body-xs" isHeavy className="break-words line-clamp-1">
          {delivery || 'Unknown'}
        </Text>
      </div>
      <div className="flex flex-row items-center gap-1">
        {getOrderDetails()}
      </div>
    </>
  );
};

export const OrderRow = ({
  order,
  asJobChild,
  showReplicatePrompt,
}: OrderRowProps) => {
  const { sendToast } = useToast();
  const navigate = useNavigate();
  const [deleteOrder, { loading: deleteLoading }] = useDeleteOrderMutation();
  const [replicateOrder, { loading: replicateLoading }] =
    useReplicateOrderMutation();
  const { initializeTemplate, originatorHasTemplate } = useInitializeTemplate({
    originatorId: order.originator?.id,
  });
  const showFeedView = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.SHOW_THREAD_MESSAGE_CLASSIFICATIONS,
  });
  const createdAtDisplay = new Date(order.createdAtDisplay);
  const data = JSON.parse(order?.extractedData ?? '{}');
  const { data: originatorConfiguredData } =
    useGetHasExtractedDataConfiguredQuery({
      variables: { id: order?.originator?.id ?? '' },
      skip: !order?.originator?.id,
    });

  const hoverClass = 'hover:shadow-sm cursor-pointer hover:bg-brand-50';
  const paddingClass = asJobChild ? 'pl-8 py-2' : 'p-2';

  const handleDelete = async () => {
    await deleteOrder({
      variables: { input: { id: order.id } },
      refetchQueries: ['GetJobs'],
    });
  };

  const handleBuildTheRest = async () => {
    await replicateOrder({
      variables: { input: { id: order.id } },
      refetchQueries: ['GetJobs'],
    }).then(() => {
      sendToast('Processing has started!', { variant: 'success' });
    });
  };

  const moreMenuLoading = deleteLoading || replicateLoading;
  const moreMenuOptions = [
    { label: 'Build the rest', onPress: handleBuildTheRest },
    { label: 'Delete', onPress: handleDelete, isDestructive: true },
  ];
  const shouldOnboardOriginator =
    originatorConfiguredData?.originatorById.hasExtractedDataConfigured ===
    false;
  const handleRowClick = () => {
    const originatorId = order?.originator?.id;
    if (shouldOnboardOriginator && originatorId) {
      if (!originatorHasTemplate) {
        initializeTemplate();
      }

      const route = getOnboardingRoute(
        OriginatorOnboardingType.FIRST_ORDER,
        order.id,
        originatorId
      );
      navigate(route);
    } else {
      navigate(`${ORDERS_ROUTE}/${order.id}`);
    }
  };

  return (
    <div
      onClick={handleRowClick}
      className={clsx(
        'flex flex-row gap-2 w-full bg-white cursor-pointer border-gray-200 justify-between',
        hoverClass,
        paddingClass
      )}
    >
      <div className="text-gray-700">
        {asJobChild ? (
          <PiDotOutlineFill />
        ) : (
          <JobIconWithColor jobType={JobType.ORDERS} />
        )}
      </div>
      <div className="flex flex-col w-full">
        {!asJobChild && (
          <div className="flex flex-row w-full items-center justify-between">
            <Text type="body-xs" className="line-clamp-1 text-gray-700" isHeavy>
              {order.originator?.name ?? 'Unknown customer'}
            </Text>
            <div className="flex flex-row items-center gap-1">
              {!showFeedView && (
                <Text
                  type="body-xs"
                  className="text-gray-500 text-right line-clamp-1"
                >
                  {formatTime(createdAtDisplay)}
                </Text>
              )}
              <MoreMenu
                moreIcon={<LuMoreVertical size={12} />}
                options={moreMenuOptions}
                isLoading={moreMenuLoading}
              />
            </div>
          </div>
        )}
        <div>
          <OrderRowDetails data={data as CompletedFreightOrderTemplate} />
          <div className="flex flex-row justify-between py-1 pr-2">
            <OrderStatusChip orderEvents={order.orderEvents} />
            {showReplicatePrompt && (
              <Button
                onPress={() => navigate(`${ORDERS_ROUTE}/${order.id}`)}
                variant="tertiary"
                size="xs"
              >
                Multiple orders detected
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
