import {
  ConfigurableOptions,
  CustomerRateType,
  JobFragment,
  LatLng,
  Maybe,
  OriginatorStatus,
  QuoteBidStatus,
  RatingMethod,
} from '@shared/generated/graphql';
import { StandardMileageUnit } from 'clerk_common';
import { CompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { Rate } from 'clerk_common/types';
import { ConfigOverrides, IntegrationConfigs } from './order';

type StandardMileage = {
  value: number;
  units: StandardMileageUnit;
};

export type MileageRate = {
  value: number;
  units: StandardMileageUnit;
};

export enum DATRatingMethod {
  FORECAST = 'FORECAST',
  HISTORY = 'HISTORY',
  RATE = 'RATE',
}

export enum GreenscreensRatingMethod {
  DEFAULT = 'DEFAULT',
  NETWORK = 'NETWORK',
}

export type RateMetadata = {
  mileage?: StandardMileage;
  mileageRate?: MileageRate;
  responseId?: string;
  rateId?: string;
  url?: string;
  response?: any;
  label?: string;
  key?: string;
  ratingMethod?: DATRatingMethod | GreenscreensRatingMethod;
};

export type CarrierRate = {
  id: string;
  createdAt: string;
  ratingMethod: RatingMethod;
  computedRate?: Rate | null;
  metadata?: RateMetadata;
};

export type CustomerRate = {
  id: string;
  createdAt: string;
  type: CustomerRateType;
  rate?: Rate | null;
  metadata?: RateMetadata;
};

export type Quote = {
  id: string;
  createdAt: string;
  status?: Maybe<QuoteBidStatus> | undefined;
  extractedData?: CompletedFreightOrderTemplate;
  organization: {
    id: string;
    name?: string;
    integrationConfigs?: IntegrationConfigs;
    configurableOptions: ConfigurableOptions;
  };
  originator?: {
    id?: string;
    name?: string;
    transmitIds: string[];
    status: OriginatorStatus;
    configOverrides?: ConfigOverrides;
  };
  carrierRates?: CarrierRate[];
  customerRates?: CustomerRate[];
  carrierRate?: CarrierRate;
  customerRate?: CustomerRate;
  routeGeoCoordinates?: LatLng[];
  job?: JobFragment;
  notesCount: number;
  externalDeepLink?: string;
};

export type QuotePreview = {
  id: string;
  createdAt: Date;
  createdAtDisplay: Date;
  updatedAt: Date;
  originator: {
    id?: string;
    name?: string;
  };
  user?: {
    id: string;
    email?: string;
  };
};
