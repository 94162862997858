import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import {
  JobsState,
  useEmailThreadContext,
} from '@shared/contexts/pluginContexts/EmailThreadContext';
import {
  AgentAction,
  AgentActionType,
  JobSummaryFragment,
  JobType,
  QuoteBidStatus,
  useGetConversationActionsQuery,
  VoomaApiClient,
} from '@shared/generated/graphql';
import { useActionRegistryContext } from '@shared/plugin/contexts/ActionRegistryContext';
import { useUpdateQuoteStatus } from '@shared/plugin/pages/Quotes/hooks/useUpdateQuoteStatus';
import { shouldShowReplicatePrompt } from '@shared/plugin/utils/shouldShowReplicatePrompt';
import { JobStatus } from '@shared/types/job';
import { QueryParamName } from 'clerk_common/constants/urls/queryParams';
import { QUOTES_ROUTE } from 'clerk_common/constants/urls/routes';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FeedItemType,
  SuggestedAction,
  SuggestedActionsFeedItem,
  SuggestedActionType,
} from '../types';

type SuggestedActionsOptions = {
  handleSendToVooma: (jobType: JobType) => Promise<void>;
  disableTransactions: boolean;
};

type SuggestedActionsState = {
  items: SuggestedActionsFeedItem[];
};

type SuggestedActionsActions = {
  executeAction: (action: SuggestedAction) => Promise<void>;
  getJobSuggestedActions: (job: JobSummaryFragment) => SuggestedAction[];
};

type SuggestedActionsValue = SuggestedActionsState & SuggestedActionsActions;

const getBatchReplyJobId = (
  threadJobs: Pick<JobsState, 'jobs'>
): string | undefined => {
  const eligibleJobs = threadJobs.jobs.filter((job) => job.quotes.length > 1);
  if (eligibleJobs.length === 1) {
    return eligibleJobs[0].id;
  }
};

const getBuildTheRestOrderId = (
  threadJobs: Pick<JobsState, 'jobs'>
): string | undefined => {
  const eligibleJobs = threadJobs.jobs.filter((job) => job.orders.length === 1);
  if (eligibleJobs.length === 1) {
    const order = eligibleJobs[0].orders[0];
    const showReplicatePrompt = shouldShowReplicatePrompt({
      parentJobStatus: JobStatus.COMPLETED,
      isOnlyOrderInJob: true,
      extractedData: JSON.parse(order.extractedData ?? '{}'),
    });
    return showReplicatePrompt ? order.id : undefined;
  }
};

const getSubmittedOrderDeepLink = (
  threadJobs: Pick<JobsState, 'jobs'>
): string | undefined | null => {
  const eligibleJobs = threadJobs.jobs.filter((job) => job.orders.length === 1);
  if (eligibleJobs.length === 1) {
    const order = eligibleJobs[0].orders[0];
    return order.externalDeepLink;
  }
};

const getQuoteReplyId = (
  threadJobs: Pick<JobsState, 'jobs'>
): string | undefined => {
  const eligibleJobs = threadJobs.jobs.filter((job) => job.quotes.length === 1);
  if (eligibleJobs.length !== 1) return undefined;

  const quote = eligibleJobs[0].quotes[0];

  return quote.customerRate?.id ? quote.id : undefined;
};

const toSuggestedAction = (
  action: AgentAction
): SuggestedAction | undefined => {
  switch (action.type) {
    case AgentActionType.MARK_QUOTE_WON:
      return {
        type: SuggestedActionType.MARK_QUOTE_WON,
        quoteId: action.entityIds?.quoteIds?.[0] ?? '',
      };
    case AgentActionType.MARK_QUOTE_QUOTED:
      return {
        type: SuggestedActionType.MARK_QUOTE_QUOTED,
        quoteId: action.entityIds?.quoteIds?.[0] ?? '',
      };
  }
};

const toSuggestedActions = (actions: AgentAction[]): SuggestedAction[] => {
  return actions
    .map(toSuggestedAction)
    .filter((action) => action !== undefined) as SuggestedAction[];
};

export const useSuggestedActions = ({
  handleSendToVooma,
  disableTransactions,
}: SuggestedActionsOptions): SuggestedActionsValue => {
  // TODO(parlato): This is a hack; we would want this to show up again if the right
  // state arises
  const [showReplicatePrompt, setShowReplicatePrompt] = useState(true);
  const { hasOrders, hasQuotes } = useMeContext();
  const { thisItem } = useEmailThreadContext();
  const {
    setBatchReplyJobId,
    batchReplyJobId: currentBatchReplyJobId,
    handleReplicate,
  } = useActionRegistryContext();
  const navigate = useNavigate();

  const { updateQuoteStatus } = useUpdateQuoteStatus();

  const externalIdentifiers = thisItem?.id ? [thisItem.id] : [];
  const { data } = useGetConversationActionsQuery({
    variables: {
      input: {
        apiClient: VoomaApiClient.OUTLOOK,
        externalIdentifiers,
      },
    },
    pollInterval: 1000,
  });
  const conversationActions = data?.conversationActions ?? [];

  const executeAction = async (action: SuggestedAction) => {
    switch (action.type) {
      case SuggestedActionType.BUILD_ORDER:
        await handleSendToVooma(JobType.ORDERS);
        break;
      case SuggestedActionType.BUILD_QUOTE:
        await handleSendToVooma(JobType.QUOTES);
        break;
      case SuggestedActionType.BUILD_REMAINING_ORDERS:
        await handleReplicate(action.orderId).then(() =>
          setShowReplicatePrompt(false)
        );
        break;
      case SuggestedActionType.DRAFT_QUOTE_REPLY:
        navigate(
          `${QUOTES_ROUTE}/${action.quoteId}?${QueryParamName.DRAFT_REPLY}=true`
        );
        break;
      case SuggestedActionType.BATCH_QUOTE_REPLY:
        setBatchReplyJobId(action.jobId);
        break;
      case SuggestedActionType.OPEN_LINK:
        window.open(action.url, '_blank');
        break;
      case SuggestedActionType.MARK_QUOTE_WON:
        updateQuoteStatus(action.quoteId, QuoteBidStatus.WON);
        break;
      case SuggestedActionType.MARK_QUOTE_QUOTED:
        updateQuoteStatus(action.quoteId, QuoteBidStatus.QUOTED);
        break;
      default:
        throw new Error(`Unknown action: ${action}`);
    }
  };

  const globalSuggestedActions = useMemo(() => {
    const actions: SuggestedAction[] = [];

    if (hasOrders && !disableTransactions) {
      actions.unshift({
        type: SuggestedActionType.BUILD_ORDER,
      });
    }

    if (hasQuotes && !disableTransactions) {
      actions.unshift({
        type: SuggestedActionType.BUILD_QUOTE,
      });
    }

    return [...actions, ...toSuggestedActions(conversationActions)];
  }, [hasOrders, hasQuotes, disableTransactions, conversationActions]);

  const getJobSuggestedActions = (job: JobSummaryFragment) => {
    const actions: SuggestedAction[] = [];

    const batchReplyJobId = getBatchReplyJobId({ jobs: [job] });
    if (hasQuotes && batchReplyJobId && !currentBatchReplyJobId) {
      actions.unshift({
        type: SuggestedActionType.BATCH_QUOTE_REPLY,
        jobId: batchReplyJobId,
      });
    }

    const quoteReplyId = getQuoteReplyId({ jobs: [job] });
    if (hasQuotes && quoteReplyId) {
      actions.unshift({
        type: SuggestedActionType.DRAFT_QUOTE_REPLY,
        quoteId: quoteReplyId,
      });
    }

    const buildTheRestOrderId = getBuildTheRestOrderId({ jobs: [job] });
    if (showReplicatePrompt && hasOrders && buildTheRestOrderId) {
      actions.unshift({
        type: SuggestedActionType.BUILD_REMAINING_ORDERS,
        orderId: buildTheRestOrderId,
      });
    }

    const submittedOrderDeepLink = getSubmittedOrderDeepLink({ jobs: [job] });
    if (submittedOrderDeepLink && hasOrders) {
      actions.unshift({
        type: SuggestedActionType.OPEN_LINK,
        url: submittedOrderDeepLink,
        title: 'Open order in TMS',
      });
    }

    // TODO(parlato) Add ...toSuggestedActions(conversationActions)
    return actions;
  };

  return {
    executeAction,
    getJobSuggestedActions,
    items: [
      {
        priority: 0,
        type: FeedItemType.SUGGESTED_ACTIONS,
        data: {
          actions: globalSuggestedActions,
        },
      },
    ],
  };
};
