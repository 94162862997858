import { CircularProgress, Tooltip } from '@shared/components';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { useConversationExternalMessages } from './hooks/useConversationExternalMessages';

const ROOT = makeRootClassName('EmailThreadActivity');
const el = makeElementClassNameFactory(ROOT);

const ProcessThreadActivity = ({ isProcessing }: { isProcessing: boolean }) => {
  return (
    <div className={el`processing-activity`}>
      {isProcessing ? (
        <Tooltip
          isInstant
          content="Vooma is processing messages on this thread"
          side="bottom"
        >
          <div>
            <CircularProgress size="xs" />
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};

export const EmailThreadActivity = () => {
  const { isProcessing } = useConversationExternalMessages();

  return <ProcessThreadActivity isProcessing={isProcessing} />;
};
