import {
  Button,
  ItemsCount,
  QuoteStatusDropdownChip,
  Text,
} from '@shared/components';
import { Dropdown } from '@shared/components/plugin-components';
import { useFlowsViewContext } from '@shared/contexts/FlowsViewContext';
import {
  FeatureFlagName,
  QuoteBidStatus,
  useDeleteQuoteMutation,
} from '@shared/generated/graphql';
import { useOrganizationFeatureFlag } from '@shared/hooks/useOrganizationFeatureFlag';
import { Quote } from '@shared/types/quote';
import { abbreviateId } from '@shared/utils/stringification/id';
import {
  DrawerTabValue,
  QueryParamName,
} from 'clerk_common/constants/urls/queryParams';
import { QUOTES_ROUTE } from 'clerk_common/constants/urls/routes';
import { EMAIL_MONITORING_SOURCES } from 'clerk_common/features/emailScanning';
import { HiSparkles } from 'react-icons/hi';
import { LuMoreVertical } from 'react-icons/lu';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useUpdateQuoteStatus } from '../Quotes/hooks/useUpdateQuoteStatus';

function QuoteSidePanelHeader({
  webBaseUrl,
  quote,
}: {
  webBaseUrl: string;
  quote: Quote | null;
}) {
  const navigate = useNavigate();

  const isQuoteTrailEnabled = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.QUOTE_TRAIL,
  });

  const { updateQuoteStatus } = useUpdateQuoteStatus();

  const { setShow: setShowFlows } = useFlowsViewContext();

  const [deleteQuote] = useDeleteQuoteMutation();

  const handleDeleteQuote = async () => {
    if (quote?.id) {
      await deleteQuote({
        variables: {
          input: {
            id: quote.id,
          },
        },
        refetchQueries: ['GetQuotes'],
      });
      navigate(-1);
    }
  };

  const abbreviatedId = abbreviateId(quote?.id || '');

  return (
    <>
      {quote ? (
        <QuoteStatusDropdownChip
          status={quote.status ?? QuoteBidStatus.NEW}
          handleSelect={(value) => updateQuoteStatus(quote.id, value)}
        />
      ) : null}
      {quote?.id && (
        <a
          href={`${webBaseUrl}${QUOTES_ROUTE}?${QueryParamName.QUOTE_ID}=${quote?.id}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            size="xs"
            icon={<MdOutlineOpenInNew className="text-brand-500" />}
            tooltip="Open in web app"
            variant="secondary"
            iconPosition="right"
          >
            Vooma
          </Button>
        </a>
      )}
      {quote?.id && isQuoteTrailEnabled && (
        <Button
          variant="secondary"
          size="xs"
          onPress={() =>
            navigate(
              `${QUOTES_ROUTE}/${quote.id}/${DrawerTabValue.AUDIT_TRAIL}`
            )
          }
          icon={
            quote.notesCount > 0 && (
              <ItemsCount count={quote.notesCount} className="ml-1" />
            )
          }
          iconPosition="right"
        >
          Audit Trail
        </Button>
      )}
      <Dropdown
        icon={<LuMoreVertical size={12} />}
        items={[
          {
            node: (
              <div className="py-[8px]" onClick={() => setShowFlows(true)}>
                <Text type="body-xs">View Flows</Text>
              </div>
            ),
          },
          {
            node: (
              <div
                className="py-[8px] text-danger-500"
                onClick={handleDeleteQuote}
              >
                <Text type="body-xs">Delete quote</Text>
              </div>
            ),
          },
          {
            node: (
              <div
                className="flex flex-row gap-[4px] py-[8px] text-gray-400 cursor-default"
                onClick={() => navigator.clipboard.writeText(quote?.id || '')}
              >
                {quote?.job?.source &&
                EMAIL_MONITORING_SOURCES.includes(quote.job.source) ? (
                  <HiSparkles className="text-indigo-300" />
                ) : null}
                <Text type="body-xs">Quote {abbreviatedId} </Text>
              </div>
            ),
          },
        ]}
        position="right-0"
      />
    </>
  );
}

export default QuoteSidePanelHeader;
