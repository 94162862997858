import {
  MessageActionButton,
  MoneyForm,
  QuoteDraftResponse,
  Separator,
} from '@shared/components';
import {
  CircularProgress,
  SlideOver,
} from '@shared/components/plugin-components';
import { OriginatorSearch } from '@shared/features/originator-search/OriginatorSearch';
import {
  FeatureFlagName,
  JobType,
  Originator,
  useOnboardNewOriginatorMutation,
  useQuoteQuery,
  useUpdateQuoteMutation,
} from '@shared/generated/graphql';

import { useAnalytics } from '@shared/hooks/useAnalytics';
import { useQuoteValidation } from '@shared/hooks/useQuoteValidation';
import useOriginators from '@shared/plugin/hooks/useOriginators';
import { quoteFromFragment } from '@shared/plugin/types/fromFragments/quote';
import { Quote } from '@shared/types/quote';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { useContext, useEffect, useRef, useState } from 'react';

import { useToast } from '@shared/components/toast';
import { CreateCustomerLocation } from '@shared/constants/identifiers';
import {
  ResponseDraftContext,
  ResponseDraftContextProvider,
} from '@shared/contexts/ResponseDraftContext';
import { ConfigureAutoResponse } from '@shared/features/configure-auto-response/ConfigureAutoResponse';
import { InformationNeeded } from '@shared/features/quote-details/InformationNeeded';
import QuoteReviewForm from '@shared/features/quote-details/QuoteReviewForm';
import { useLaneHistory } from '@shared/hooks/useLaneHistory';
import { useOrganizationFeatureFlag } from '@shared/hooks/useOrganizationFeatureFlag';
import { useSaveContactCue } from '@shared/hooks/useSaveContactCue';
import { QueryParamName } from 'clerk_common/constants/urls/queryParams';
import { QUOTES_ROUTE } from 'clerk_common/constants/urls/routes';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { QuoteLaneHistory } from './components/QuoteLaneHistory/QuoteLaneHistory';
import { RatesUnavailableWarning } from './components/RatesUnavailableWarning';
import QuoteSidePanelHeader from './QuoteSidePanelHeader';
import { shouldShowRates } from './utils/shouldShowRates';

const POLLING_INTERVAL = 10 * 1000;

const ROOT = makeRootClassName('QuoteSidePanel');
export const el = makeElementClassNameFactory(ROOT);

type OriginatorOption = {
  id: string;
  label: string;
  details: string;
};
interface QuoteSidePanelProps {
  quote: Quote;
  originatorOptions: OriginatorOption[];
  loading: boolean;
  updateOriginator: (originatorId: string) => void;
  originatorsSearchTerm?: string;
  handleOriginatorSearch: (query?: string) => void;
  isEditing?: boolean;
  setIsEditing: (isEditing: boolean) => void;
  webBaseUrl: string;
}

const QuoteSidePanel = ({
  quote,
  originatorOptions,
  loading,
  updateOriginator,
  handleOriginatorSearch,
  isEditing,
  setIsEditing,
  webBaseUrl,
}: QuoteSidePanelProps) => {
  const isAutoReplyConfigEnabled = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.QUOTES_CONDITIONAL_AUTO_REPLY,
  });
  const { quotes } = useLaneHistory(quote, quote.originator?.id);
  const { validation, loading: loadingValidation } = useQuoteValidation(quote);
  const [onboardNewOriginator] = useOnboardNewOriginatorMutation();
  const { sendToast } = useToast();
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);
  const {
    draftReply,
    draftReplyInProgress,
    composeMessage,
    composeMessageInProgress,
  } = useContext(ResponseDraftContext);

  useEffect(() => {
    const draftReply = new URLSearchParams(window.location.search).get(
      QueryParamName.DRAFT_REPLY
    );
    if (!isNil(draftReply)) {
      setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollTo({
            top: scrollRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, 200);
    }
  }, [scrollRef.current]);

  const sendErrorToast = (e: Error) => {
    sendToast('Something went wrong', {
      description: e.message,
      variant: 'error',
      isDismissible: true,
    });
  };

  const quotingConfig =
    quote.organization.integrationConfigs?.integrations.find(
      (i) => i.orderType === 'QUOTE'
    );
  const hasIntegrationConfig = Boolean(quotingConfig);
  const informationIsMissing = !validation?.valid;
  const showRates = shouldShowRates(quote, validation);

  const createOriginator = async (name: string) => {
    const res = await onboardNewOriginator({
      variables: {
        input: {
          name,
          organizationId: quote.organization.id,
        },
      },
    });
    const orig = res.data?.onboardNewOriginator?.customerFacingOriginator;
    const originatorId = orig?.id;
    track('Clicked Create Customer', {
      originatorName: name,
      originatorId,
      name: CreateCustomerLocation.QUOTE_ORIGINATOR_DROPDOWN,
    });
    if (!originatorId) {
      sendErrorToast(new Error('Could not create originator'));
    } else {
      sendToast(`${orig?.name ?? 'New customer'} successfully created`, {
        variant: 'success',
      });
    }
    return originatorId;
  };

  return (
    <div className={el`quote-container`}>
      <div className={el`quote-header`}>
        {informationIsMissing && !loadingValidation && <InformationNeeded />}
        <div className="flex mx-4 mb-4">
          <OriginatorSearch
            handleSearch={handleOriginatorSearch}
            originator={quote.originator as Originator}
            originatorOptions={originatorOptions}
            updateOriginator={updateOriginator}
            loading={loading}
            createOriginator={createOriginator}
          />
        </div>
      </div>
      <div
        className={clsx(el`quote-details`, el`shared-quote-details`)}
        ref={scrollRef}
      >
        <QuoteReviewForm
          quote={quote}
          isLoading={loading}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
        <Separator />
        {quotes?.length ? (
          <>
            <QuoteLaneHistory
              quotes={quotes}
              quoteId={quote.id}
              navigateToQuote={(quoteId: string) =>
                navigate(`${QUOTES_ROUTE}/${quoteId}`)
              }
            />
            <Separator />
          </>
        ) : undefined}
        {showRates ? (
          <MoneyForm
            quote={quote}
            compact
            hideGetRates={!hasIntegrationConfig}
            webBaseUrl={webBaseUrl}
          />
        ) : (
          <RatesUnavailableWarning loading={loadingValidation} el={el} />
        )}
        <Separator />
        <QuoteDraftResponse compact />
      </div>
      <MessageActionButton
        className="sticky bottom-0 bg-white p-4 border-t"
        draftReply={draftReply}
        draftReplyInProgress={draftReplyInProgress}
        composeMessage={composeMessage}
        composeMessageInProgress={composeMessageInProgress}
      />
      {isAutoReplyConfigEnabled && <ConfigureAutoResponse />}
    </div>
  );
};

type QuoteSidePanelContainerProps = {
  webBaseUrl: string;
};
const QuoteSidePanelContainer = ({
  webBaseUrl,
}: QuoteSidePanelContainerProps) => {
  const { page } = useAnalytics();
  const navigate = useNavigate();
  const { quoteId } = useParams();
  const {
    data,
    loading: loadingQuote,
    stopPolling,
    startPolling,
  } = useQuoteQuery({
    variables: {
      id: quoteId ?? '',
    },
    pollInterval: POLLING_INTERVAL,
    skip: !quoteId,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [originatorsSearchTerm, setOriginatorsSearchTerm] = useState<string>();
  const { originators, isLoading: loadingOriginators } = useOriginators({
    organizationId: data?.quoteById?.organization?.id,
    id:
      originatorsSearchTerm === undefined
        ? data?.quoteById?.originator?.id
        : undefined,
    searchTerm: originatorsSearchTerm,
  });
  const originatorOptions = originators.map((o) => ({
    id: o.id,
    label: o.name,
    details: o.transmitIds?.join(','),
  }));
  const [updateQuote] = useUpdateQuoteMutation();
  const { handleSendCue } = useSaveContactCue();

  useEffect(() => {
    if (quoteId) {
      startPolling(POLLING_INTERVAL);
    } else {
      stopPolling();
    }

    return () => stopPolling();
  }, [quoteId]);

  const handleOriginatorSearch = (query?: string) => {
    setOriginatorsSearchTerm(query);
  };

  const quote = data?.quoteById ? quoteFromFragment(data?.quoteById) : null;

  const updateOriginator = async (originatorId: string) => {
    const id = data?.quoteById?.id;
    if (!id) return;

    const res = await updateQuote({
      variables: {
        input: {
          id,
          originatorId,
        },
      },
    });

    const previousOriginatorId = data?.quoteById?.originator?.id;
    if (!previousOriginatorId) {
      handleSendCue({ job: quote?.job, originatorId, jobType: JobType.QUOTES });
    }

    return res;
  };

  useEffect(() => {
    if (quote?.id) {
      page('Quote', { quoteId: quote?.id });
    }
  }, [quote?.id]);

  const loading = loadingQuote || loadingOriginators;
  const itemId = quote?.job?.emailMetadata?.itemId as string | undefined;

  return (
    <SlideOver // TODO(parlato): Remove the slide over transitions and use framer motion
      title={''}
      show
      header={<QuoteSidePanelHeader quote={quote} webBaseUrl={webBaseUrl} />}
      onClose={() => navigate('/')}
    >
      {loading && !quote && (
        <div className="w-full h-1/3 flex items-center justify-center">
          <CircularProgress size="md" />
        </div>
      )}
      {!loading && !quote && <div className="p-2">Could not find quote</div>}
      {quote && itemId && (
        <ResponseDraftContextProvider
          quoteIds={[quote.id]}
          itemId={itemId}
          respondToEmail={quote.job?.emailMetadata?.originatorAddress}
        >
          <QuoteSidePanel
            quote={quote as Quote}
            originatorOptions={originatorOptions}
            originatorsSearchTerm={originatorsSearchTerm}
            handleOriginatorSearch={handleOriginatorSearch}
            updateOriginator={updateOriginator}
            loading={loading}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            webBaseUrl={webBaseUrl}
          />
        </ResponseDraftContextProvider>
      )}
    </SlideOver>
  );
};

export default QuoteSidePanelContainer;
