import {
  CompletedTemplateChildConfigs,
  FormValue,
  FormValueChild,
  IntermediateTemplateField,
  ListValuedIntermediateTemplateField,
  ObjectValuedIntermediateTemplateField,
} from 'clerk_common/templates/types';
import { cloneDeep } from 'lodash';
import { isListValued, isObjectValued, updatePath } from './pathHelpers';

export type ObjMutationSync = (
  template: IntermediateTemplateField,
  path?: string
) => IntermediateTemplateField;
export type FieldMutationSync = (
  child: CompletedTemplateChildConfigs,
  path?: string
) => CompletedTemplateChildConfigs;
export type ListMutationSync = (
  child: ListValuedIntermediateTemplateField,
  path?: string
) => ListValuedIntermediateTemplateField;

export interface MutationsSync {
  obj?: ObjMutationSync;
  field?: FieldMutationSync;
  list?: ListMutationSync;
}

export function mutateByFieldTypeSync(
  template: FormValueChild,
  mutations: MutationsSync,
  path: string = ''
): FormValueChild {
  if (isObjectValued(template)) {
    return mutateObjSync(template, mutations, path);
  } else if (isListValued(template)) {
    return mutateListSync(template, mutations, path);
  } else {
    return mutateFieldSync(template, mutations, path);
  }
}

function mutateObjElementsSync(
  d: FormValue,
  mutations: MutationsSync,
  path: string
): FormValue {
  const ret: FormValue = {};
  for (const [key, value] of Object.entries(d)) {
    ret[key] = mutateByFieldTypeSync(value, mutations, updatePath(key, path));
  }
  return ret;
}

function mutateObjSync(
  template: ObjectValuedIntermediateTemplateField,
  mutations: MutationsSync,
  path: string
): ObjectValuedIntermediateTemplateField {
  const ret = cloneDeep(template);
  ret._value = mutateObjElementsSync(
    ret._value,
    mutations,
    updatePath('_value', path)
  );

  if (mutations.obj) {
    return mutations.obj(ret, path) as ObjectValuedIntermediateTemplateField;
  }

  return ret;
}

function mutateListSync(
  template: ListValuedIntermediateTemplateField,
  mutations: MutationsSync,
  path: string
): ListValuedIntermediateTemplateField {
  const ret = cloneDeep(template);
  ret._value = template._value.map((x, i) =>
    mutateObjElementsSync(x, mutations, updatePath(`_value.${i}`, path))
  );
  if (template._added) {
    ret._added = template._added.map((x, i) =>
      mutateObjElementsSync(x, mutations, updatePath(`_added.${i}`, path))
    );
  }

  if (mutations.list) {
    return mutations.list(ret, path) as ListValuedIntermediateTemplateField;
  }

  return ret;
}

function mutateFieldSync(
  template: CompletedTemplateChildConfigs,
  mutations: MutationsSync,
  path: string
): CompletedTemplateChildConfigs {
  if (!mutations.field) {
    return template;
  }
  return mutations.field(template, path);
}
