import { Button, MoreMenu, Text } from '@shared/components';
import { CircularProgress } from '@shared/components/plugin-components';
import {
  JobSummaryFragment,
  JobType,
  ProcessingStatus,
  useDeleteJobMutation,
} from '@shared/generated/graphql';
import { JobIconWithColor } from '@shared/plugin/components/JobIcon';
import { JobEmailMetadata } from '@shared/types/job';
import { handlePlural } from 'clerk_common/stringification/handlePlural';
import { FeatureFlagName } from 'clerk_common/types';
import clsx from 'clsx';
import { LuMoreVertical } from 'react-icons/lu';
import { PiArrowBendDoubleUpLeftBold } from 'react-icons/pi';

import { useAnalytics } from '@shared/hooks/useAnalytics';
import { useOrganizationFeatureFlag } from '@shared/hooks/useOrganizationFeatureFlag';
import { formatTime } from '@shared/plugin/utils/datetime';
import { ChildSelection } from './JobCard';

function jobIsProcessing(status: ProcessingStatus) {
  return (
    status === ProcessingStatus.NEW || status === ProcessingStatus.PROCESSING
  );
}

function jobIsComplete(status: ProcessingStatus) {
  return status === ProcessingStatus.COMPLETED;
}

function containsQuotes(job: JobSummaryFragment) {
  return job.quotes.length > 0;
}

function inferJobType(job: JobSummaryFragment) {
  if (job.quotes.length > 0) return JobType.QUOTES;
  if (job.orders.length > 0) return JobType.ORDERS;
  return null;
}

function getOriginator(job: JobSummaryFragment) {
  if (job.originator) return job.originator;
  if (job.orders.length > 0) return job.orders[0].originator;
  if (job.quotes.length > 0) return job.quotes[0].originator;
  return null;
}

function getTitle(job: JobSummaryFragment, emailMetadata: JobEmailMetadata) {
  const originator = getOriginator(job);
  if (originator) return originator.name;
  return emailMetadata?.originatorAddress ?? 'Unknown sender';
}

function describeChildren(job: JobSummaryFragment) {
  const orders = job.orders.length;
  const quotes = job.quotes.length;
  const quoteStr = handlePlural(quotes, 'quote', 'quotes');
  const orderStr = handlePlural(orders, 'order', 'orders');
  if (orders > 0 && quotes > 0) {
    return `${orderStr}, ${quoteStr}`;
  }
  if (orders > 0) return orderStr;
  if (quotes > 0) return quoteStr;
  return '';
}

interface JobCardHeaderProps {
  job: JobSummaryFragment;
  initialJobType: JobType;
  isExpanded: boolean;
  toggleExpanded: () => void;
  childSelectionState: ChildSelection;
}

export const JobCardHeader = ({
  job,
  initialJobType,
  isExpanded,
  toggleExpanded,
  childSelectionState,
}: JobCardHeaderProps) => {
  const [deleteJob] = useDeleteJobMutation();
  const { track } = useAnalytics();

  const showFeedView = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.SHOW_THREAD_MESSAGE_CLASSIFICATIONS,
  });
  const hoverClass = 'hover:shadow-sm cursor-pointer hover:bg-brand-50';
  const isProcessing = jobIsProcessing(job.status);
  const createdAt = new Date(job.createdAt);
  const emailMetadata = job.emailMetadata as JobEmailMetadata;
  const title = getTitle(job, emailMetadata);
  const subtitle = emailMetadata?.subject ?? 'No subject';
  const jobType = inferJobType(job) ?? initialJobType;
  const hasChildren = job.orders.length > 0 || job.quotes.length > 0;
  const {
    showSelection,
    setShowSelection,
    selectableIds,
    selectedIds,
    setSelectedIds,
  } = childSelectionState;

  const handleDelete = async () => {
    await deleteJob({
      variables: { input: { id: job.id } },
      refetchQueries: ['GetJobs'],
    });
  };

  const moreMenuOptions = [
    { label: 'Delete', onPress: handleDelete, isDestructive: true },
  ];

  if (jobIsComplete(job.status) && !hasChildren) return null;

  return (
    <div
      onClick={toggleExpanded}
      className={clsx(
        'flex flex-row items-start justify-start gap-2 p-2',
        hoverClass
      )}
    >
      <JobIconWithColor jobType={jobType} />
      <div className="flex flex-col items-start flex-1">
        <div className="flex flex-row w-full items-center justify-between">
          <Text type="body-xs" isHeavy className="line-clamp-1 text-gray-700">
            {title}
          </Text>
          <div className="flex flex-row items-center gap-1">
            {!showFeedView && (
              <Text type="body-xs" className="text-gray-500 text-right">
                {formatTime(createdAt)}
              </Text>
            )}
            <MoreMenu
              moreIcon={<LuMoreVertical size={12} />}
              options={moreMenuOptions}
            />
          </div>
        </div>
        <Text type="body-xs" className="tet-black line-clamp-1">
          {subtitle}
        </Text>
        {hasChildren && (
          <div className="flex flex-row w-full items-center justify-between">
            <Text type="body-xs" className="text-gray-500 line-clamp-1">
              {describeChildren(job)}
            </Text>
            {!isProcessing && containsQuotes(job) && (
              <Button
                variant="tertiary"
                size="xs"
                icon={
                  showSelection ? null : (
                    <PiArrowBendDoubleUpLeftBold size={10} />
                  )
                }
                onPress={() => {
                  if (!isExpanded && !showSelection) toggleExpanded();
                  setSelectedIds(
                    selectedIds.length > 0 ? selectedIds : selectableIds
                  );
                  if (showSelection) {
                    track('Canceled Batch Reply', { jobId: job.id });
                  } else {
                    track('Clicked Batch Reply', { jobId: job.id });
                  }
                  console.log('HERE HERE');
                  setShowSelection((prev) => (prev ? undefined : job.id));
                }}
              >
                {showSelection ? 'Cancel reply' : 'Batch reply'}
              </Button>
            )}
          </div>
        )}
        <div className="flex flex-row mt-1 items-center gap-1">
          <div className="w-1/10">
            {isProcessing ? <CircularProgress size="xs" /> : null}
          </div>
          <Text type="body-xs" className="text-gray-500">
            {isProcessing ? 'Generating...' : ''}
          </Text>
        </div>
      </div>
    </div>
  );
};
