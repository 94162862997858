import Intercom, { onHide, onShow } from '@intercom/messenger-js-sdk';
import { Text, Tooltip } from '@shared/components';
import { INTERCOM_CUSTOM_LAUNCHER_ID } from '@shared/constants';

import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { createContext, useEffect, useState } from 'react';
import { PiChatCircleFill } from 'react-icons/pi';

const INTERCOM_APP_ID = 'dk9v1s2a';

export type IntercomSupportContextType = {
  isInitialized: boolean;
  displayLauncher: (show: boolean) => void;
};

export const IntercomSupportContext = createContext<IntercomSupportContextType>(
  {
    isInitialized: false,
    displayLauncher: () => {},
  }
);

export const IntercomSupportContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  // NOTE(parlato): Apparently, Intercom has no API to determine this
  // programmatically, so we have to set it manually.
  // https://github.com/v3rm0n/intercom_flutter/issues/291
  const [isInitialized, setIsInitialized] = useState(false);

  const { me, defaultOrgName, defaultOrgId } = useMeContext();
  const { track } = useAnalytics();
  const [display, setDisplay] = useState(true);

  function handleDisplay(show: boolean) {
    setDisplay(show);
  }

  useEffect(() => {
    if (!isInitialized && me?.username && me?.email && me?.intercomUserHash) {
      Intercom({
        app_id: INTERCOM_APP_ID,
        user_id: me.id,
        user_hash: me.intercomUserHash as string,
        name: me.username,
        email: me.email,
        organization_id: defaultOrgId,
        organization_name: defaultOrgName,
        hide_default_launcher: true,
        custom_launcher_selector: `#${INTERCOM_CUSTOM_LAUNCHER_ID}`,
      });
      setIsInitialized(true);

      onShow(() => track('Show Intercom'));

      onHide(() => track('Hide Intercom'));
    }
  }, [me, isInitialized]);

  return (
    <IntercomSupportContext.Provider
      value={{
        isInitialized,
        displayLauncher: handleDisplay,
      }}
    >
      {children}
      {display && (
        <>
          <div
            id={INTERCOM_CUSTOM_LAUNCHER_ID}
            className="absolute right-1 bottom-1 cursor-pointer z-50"
          >
            <Tooltip
              isInstant
              content="Chat with Vooma support live"
              side="left"
            >
              <div className="relative">
                <PiChatCircleFill className="w-8 h-8 opacity-90 text-brand-400  hover:text-brand-600 rounded-lg" />
                <Text isHeavy className="text-white absolute top-1 left-[11px]">
                  ?
                </Text>
              </div>
            </Tooltip>
          </div>
        </>
      )}
    </IntercomSupportContext.Provider>
  );
};
