import { ConversationMessage } from '@shared/plugin/pages/Home/components/email-thread-activity/types';
import { findMaxConfidenceClass } from '@shared/plugin/pages/Home/components/email-thread-activity/utils/findMaxConfidenceClass';
import { hasProbableClassification } from '@shared/plugin/pages/Home/components/email-thread-activity/utils/hasProbableClass';
import { uniqBy } from 'lodash';
import { MessageClassificationFeedItem } from '../types';
import { toMessageClassificationFeedItem } from '../utils/toMessageClassificationFeedItem';

export const transformConversationExternalMessages = (
  messages: ConversationMessage[]
): MessageClassificationFeedItem[] =>
  uniqBy(messages.filter(hasProbableClassification), 'externalIdentifier')
    .map((message) => {
      const maxConfidenceClass = findMaxConfidenceClass(message);
      return {
        ...message,
        maxConfidenceClass,
      };
    })
    .sort((a, b) => {
      if (!a || !b) return 0;

      const aTime = a.emailMetadata?.receivedDateTime || '';
      const bTime = b.emailMetadata?.receivedDateTime || '';
      return new Date(bTime).getTime() - new Date(aTime).getTime();
    })
    .map(toMessageClassificationFeedItem);
