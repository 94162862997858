import { Button } from '@shared/components/button';
import { Link } from '@shared/components/link';
import { Quote, RateMetadata } from '@shared/types/quote';
import { RatingMethod } from 'clerk_common/types/pricingStrategy';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { maybeGetDATRateUrl } from '../utils/getGroupedRatesUrl';

type LinkToRateProps = {
  isDATRateViewAutomationEnabled?: boolean;
  isDATLoadSearchAutomationEnabled?: boolean;
  metadata?: RateMetadata;
  ratingMethod?: RatingMethod;
  quote: Quote;
  optionalLabel?: string;
};

export function LinkToRate(p: LinkToRateProps) {
  const datUrl =
    p.isDATRateViewAutomationEnabled || p.isDATLoadSearchAutomationEnabled
      ? maybeGetDATRateUrl(
          p.quote,
          p.ratingMethod,
          p.isDATRateViewAutomationEnabled,
          p.isDATLoadSearchAutomationEnabled
        )
      : undefined;
  const rateUrl = datUrl || p.metadata?.url;
  if (!rateUrl) return <div />;

  return (
    <Link size="xs" href={rateUrl} target="_blank" className="link">
      <div>
        <Button
          size="xs"
          icon={<MdOutlineOpenInNew className="text-brand-500" />}
          tooltip={p.optionalLabel || 'View rate source'}
          variant="tertiary"
          iconPosition="right"
        >
          {p.optionalLabel || 'View rate source'}
        </Button>
      </div>
    </Link>
  );
}
