import { ApolloQueryResult } from '@apollo/client';
import {
  QuoteValidationPayload,
  QuoteValidationQuery,
  useQuoteValidationQuery,
} from '@shared/generated/graphql';
import { Quote } from '@shared/types/quote';
import { useEffect } from 'react';

interface QuoteValidationState {
  validation?: QuoteValidationPayload;
  loading: boolean;
  refetch: (id: string) => Promise<ApolloQueryResult<QuoteValidationQuery>>;
}

export const useQuoteValidation = (quote: Quote): QuoteValidationState => {
  const {
    data,
    loading,
    refetch: refetchQuoteValidation,
  } = useQuoteValidationQuery({
    variables: {
      id: quote.id,
    },
  });

  const refetch = async (id: string) => {
    return refetchQuoteValidation({
      id,
    });
  };

  useEffect(() => {
    refetch(quote.id);
  }, [quote.originator, quote.organization, quote.extractedData]);

  return {
    validation: data?.quoteById?.validation,
    loading,
    refetch,
  };
};
