import { AnalyticsContext } from '@shared/contexts/AnalyticsContext';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useEmailThreadContext } from '@shared/contexts/pluginContexts/EmailThreadContext';
import { useConversationExternalMessagesQuery } from '@shared/generated/graphql';
import { ExternalMessageClassifications } from 'clerk_common';
import { useContext, useMemo } from 'react';
import { ConversationMessage, ExternalMessageFragment } from '../types';

const fromExternalMessageFragment = (
  message: ExternalMessageFragment
): ConversationMessage => {
  return {
    id: message.id,
    type: message.type,
    externalIdentifier: message.externalIdentifier,
    status: message.status,
    emailMetadata: {
      receivedDateTime: message.emailMetadata?.receivedDateTime,
      sender: message.emailMetadata?.sender,
    },
    classifications: message.classifications as ExternalMessageClassifications,
    userId: message.user?.id,
  };
};

export const useConversationExternalMessages = () => {
  const { defaultOrgId } = useMeContext();
  const { thisItem } = useEmailThreadContext();
  const { apiClient } = useContext(AnalyticsContext);

  const { data, loading } = useConversationExternalMessagesQuery({
    variables: {
      input: {
        apiClient,
        organizationId: defaultOrgId,
        externalIdentifiers: thisItem?.id ? [thisItem.id] : [],
      },
    },
    pollInterval: 5 * 1000,
  });

  const messages = useMemo(() => {
    if (!data?.conversationExternalMessages?.length) return [];

    const messages =
      data.conversationExternalMessages as ExternalMessageFragment[];

    return messages.map(fromExternalMessageFragment);
  }, [data]);

  const isProcessing = useMemo(() => {
    if (!data?.conversationExternalMessages?.length) return false;

    return data.conversationExternalMessages.some((message) =>
      ['NEW', 'IN_PROGRESS', 'PENDING_CLASSIFICATION'].includes(message.status)
    );
  }, [data]);

  return { messages, loading, isProcessing };
};
