import { Button, CircularProgress } from '@shared/components';
import { useLoadInitialValues } from '@shared/components/react-hook-form';
import { Text } from '@shared/components/text';
import { useToast } from '@shared/components/toast';
import { useUpdateUserFeaturesMutation } from '@shared/generated/graphql';
import { User } from '@shared/types/user';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import EmailScanningFeatureForm from './EmailScanning';
import {
  EmailScanningFeatureFormValues,
  getInitialEmailScanningFormData,
  getUpdatedFeatures,
} from './utils/featuresForm';

export type UserFeaturesFormProps = StyleProps & {
  isLoading: boolean;
  onDiscard: () => void;
  organizationId: string;
  user?: User;
  disableAdminControl?: boolean;
};

const ROOT = makeRootClassName('UserFeaturesForm');
const el = makeElementClassNameFactory(ROOT);

export function UserFeaturesForm(props: UserFeaturesFormProps): ReactElement {
  const [updateUserFeatures, { loading }] = useUpdateUserFeaturesMutation();
  const { sendToast } = useToast();
  const initialEmailScanningFormData = getInitialEmailScanningFormData(
    props.organizationId,
    props.user
  );

  const { control, reset, handleSubmit } =
    useForm<EmailScanningFeatureFormValues>({
      defaultValues: initialEmailScanningFormData,
    });
  useLoadInitialValues(reset, initialEmailScanningFormData);

  const submitHandler: SubmitHandler<EmailScanningFeatureFormValues> = async (
    data
  ) => {
    if (!props.user?.id) return;

    const updatedFeatures = getUpdatedFeatures(
      data,
      props.organizationId,
      props.user
    );

    const res = await updateUserFeatures({
      variables: {
        input: {
          id: props.user?.id,
          features: {
            features: updatedFeatures,
          },
        },
      },
    });

    if (res.data?.updateUserFeatures.user) {
      sendToast('Feature updated!', { variant: 'success' });
      props.onDiscard();
    } else {
      sendToast('Failed to update feature.', { variant: 'error' });
    }
  };

  return (
    <div className={clsx(ROOT, props.className)}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className={el`user-features`}>
          <Text type="body-md" isHeavy>
            Features
          </Text>
        </div>
        <EmailScanningFeatureForm
          el={el}
          control={control}
          disableAdminControl={props.disableAdminControl}
        />
        <div className={el`footer`}>
          <Button variant="secondary" onPress={props.onDiscard} type="button">
            Discard
          </Button>
          <Button
            variant="primary"
            type="submit"
            isDisabled={loading || props.isLoading}
          >
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  );
}

type UserFeaturesFormContainerProps = StyleProps & {
  onDiscard(): void;
  user?: User;
  organizationId: string;
  disableAdminControl?: boolean;
};

export default function UserFeaturesFormContainer({
  onDiscard,
  organizationId,
  user,
  disableAdminControl,
  ...styleProps
}: UserFeaturesFormContainerProps) {
  const isLoading = false;
  const isUpdating = false;
  return (
    <>
      {isLoading ? (
        <div className="w-full flex justify-center">
          <CircularProgress className="my-[300px]" />
        </div>
      ) : (
        <UserFeaturesForm
          isLoading={isUpdating}
          organizationId={organizationId}
          onDiscard={onDiscard}
          user={user}
          disableAdminControl={disableAdminControl}
          {...styleProps}
        />
      )}
    </>
  );
}
