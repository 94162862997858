import { JobType } from '@shared/generated/graphql';
import clsx from 'clsx';
import { LuMail } from 'react-icons/lu';
import { PiCurrencyDollarBold, PiTrayBold } from 'react-icons/pi';

type JobIconProps = {
  jobType: JobType;
};
export function JobIcon({ jobType }: JobIconProps) {
  switch (jobType) {
    case JobType.ORDERS:
      return <PiTrayBold size={14} />;
    case JobType.QUOTES:
      return <PiCurrencyDollarBold size={14} />;
    default:
      return <LuMail size={14} />;
  }
}

export function JobIconWithColor({ jobType }: JobIconProps) {
  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-md p-1',
        jobType === JobType.ORDERS
          ? 'text-brand-600 bg-brand-100'
          : 'text-orange-600 bg-orange-100'
      )}
    >
      {<JobIcon jobType={jobType} />}
    </div>
  );
}
