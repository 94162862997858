import { InteractableComponent } from '@shared/components/interactable-component';
import { Tooltip } from '@shared/components/tooltip';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { getObjectValue } from 'clerk_common/templates/getValue';
import { isEmpty, isNil } from 'lodash';
import { useState } from 'react';
import { PlusCircle, Trash } from 'react-feather';
import { Path, useFormContext } from 'react-hook-form';
import { useInternalFormContext } from '../../internalFormContext/InternalFormContext';
import { EditableJSONField, FORM_STATE_FIELD } from '../../types';
import { AppointmentTime } from './AppointmentTime';
import { TimeRange } from './TimeRange';

const ROOT = makeRootClassName('TimeEntity');
export const el = makeElementClassNameFactory(ROOT);

type TimeData = {
  startTimeOrOnlyTime: EditableJSONField;
  optionalEndTime: EditableJSONField;
};

export type TimeFieldsData = {
  _value: TimeData;
  _display: {
    name: string;
  };
};

type OptionalTimeRangeProps = {
  data: TimeFieldsData;
  prefix?: string;
};

export function OptionalTimeRange({ data, prefix }: OptionalTimeRangeProps) {
  const { setValue } = useFormContext<EditableJSONField>();
  const { save } = useInternalFormContext();
  const timeRange: TimeData = getObjectValue(data);
  const startTime = timeRange.startTimeOrOnlyTime;
  const endTime = timeRange.optionalEndTime;
  const endTimeValue = !isNil(endTime) ? getObjectValue(endTime) : '';
  const [timeRangeEnabled, setTimeRangeEnabled] = useState(
    !isEmpty(endTimeValue)
  );

  if (isNil(startTime) && isNil(endTime)) {
    console.warn(
      'OptionalTimeRange created without time range fields specified in the template..'
    );
    return null;
  }

  const iconDescription = 'End Time';
  return timeRangeEnabled ? (
    <div className={el`oneline`}>
      <TimeRange startTime={startTime} endTime={endTime} prefix={prefix} />
      <InteractableComponent
        className={el`remove-end-button`}
        onPress={() => {
          setValue(
            `${prefix}_value.optionalEndTime.${FORM_STATE_FIELD}.` as Path<EditableJSONField>,
            ''
          );
          save();
          setTimeRangeEnabled(false);
        }}
      >
        <Tooltip content={`Delete ${iconDescription}`}>
          <Trash size={12} />
        </Tooltip>
      </InteractableComponent>
    </div>
  ) : (
    <div className={el`oneline`}>
      <AppointmentTime time={startTime} prefix={prefix} />
      <InteractableComponent
        className={el`add-end-button`}
        onPress={() => {
          setTimeRangeEnabled(true);
        }}
      >
        <Tooltip content={`Add ${iconDescription}`}>
          <PlusCircle size={12} />
        </Tooltip>
      </InteractableComponent>
    </div>
  );
}
