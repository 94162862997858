import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import { ReactNode } from 'react';

const NOTISTACK_OPTIONS: Omit<SnackbarProviderProps, 'children'> = {
  maxSnack: 2,
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
  autoHideDuration: 3000,
};

export function ToastProvider(p: { children?: ReactNode }) {
  const { children } = p;

  return <SnackbarProvider {...NOTISTACK_OPTIONS}>{children}</SnackbarProvider>;
}
