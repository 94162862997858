import { createCheck, createCopy } from '@shared/assets/icons';
import { useOptionalRef } from '@shared/hooks';
import { makeRootClassName, StyleProps } from '@shared/utils';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, ReactElement, useState } from 'react';
import { Button, ButtonProps } from '..';

const COPY_ICON = createCopy;
const CHECK_ICON = createCheck;

// NOTE(parlato): Fallback method when navigator clipboard
// API is unavailable
const copyElementText = (textToCopy: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;
  textArea.style.opacity = '0';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand('copy');

  document.body.removeChild(textArea);
};

export type CopyButtonProps = StyleProps &
  Omit<ButtonProps, 'children' | 'onPress' | 'icon'> & {
    textToCopy: string;
    label?: string;
    onCopy?: () => void;
  };

const ROOT = makeRootClassName('CopyButton');

const DEFAULT_PROPS = {} as const;

function CopyButton(
  props: CopyButtonProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const domRef = useOptionalRef(ref);

  const { textToCopy, label, onCopy, ...restButtonProps } = p;
  const [recentlyPressed, setRecentlyPressed] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch {
      copyElementText(textToCopy);
    }

    onCopy && onCopy();
    setRecentlyPressed(true);
    setTimeout(() => {
      setRecentlyPressed(false);
    }, 1000);
  };

  return (
    <div ref={domRef} className={clsx(ROOT, p.className)}>
      <Button
        tooltip="Copy to clipboard"
        {...restButtonProps}
        icon={recentlyPressed ? CHECK_ICON : COPY_ICON}
        onPress={handleCopy}
      >
        {label}
      </Button>
    </div>
  );
}

export const CopyButtonForwardRef = forwardRef<HTMLDivElement, CopyButtonProps>(
  CopyButton
);

export default CopyButtonForwardRef;
