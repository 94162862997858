import {
  JobSummaryFragment,
  JobType,
  QuoteEventType,
} from '@shared/generated/graphql';
import { ConversationMessage } from '@shared/plugin/pages/Home/components/email-thread-activity/types';
import { ExternalMessageClassification } from 'clerk_common';

export type FeedFilterParams = {
  selectedOption: FeedFilterOption;
  setSelectedOption: (state: FeedFilterOption) => void;
  filterOptions: FeedFilterOption[];
};

export enum FeedFilterOption {
  ACTIVITY = 'ACTIVITY',
  QUOTES = 'QUOTES',
  ORDERS = 'ORDERS',
}

export enum FeedItemType {
  ACTION = 'ACTION',
  COMMENT = 'COMMENT',
  MESSAGE_CLASSIFICATION = 'MESSAGE_CLASSIFICATION',
  SUGGESTED_ACTIONS = 'SUGGESTED_ACTIONS',
  JOB_CARD = 'JOB_CARD',
  QUOTE_EVENTS = 'QUOTE_EVENTS',
}

export type FeedItem =
  | SuggestedActionsFeedItem
  | ActionFeedItem
  | QuoteEventsFeedItem
  | CommentFeedItem
  | MessageClassificationFeedItem
  | JobCardFeedItem;

type FeedItemBase = {
  createdAt?: string;
  createdBy?: string;
  priority?: number;
  type: FeedItemType;
};

export type ActionFeedItem = FeedItemBase & {
  type: FeedItemType.ACTION;
};

export type FeedQuoteEvent = {
  createdAt: string;
  type: QuoteEventType;
  quoteId: string;
};

export type QuoteEventsFeedItem = FeedItemBase & {
  type: FeedItemType.QUOTE_EVENTS;
  data: {
    quoteEvents: FeedQuoteEvent[];
  };
};

export type CommentFeedItem = FeedItemBase & {
  type: FeedItemType.COMMENT;
};

export type JobCardFeedItem = FeedItemBase & {
  type: FeedItemType.JOB_CARD;
  data: {
    job: JobSummaryFragment;
    initialJobType: JobType;
  };
};

export type MessageClassificationFeedItem = FeedItemBase & {
  type: FeedItemType.MESSAGE_CLASSIFICATION;
  data: ConversationMessage & {
    maxConfidenceClass: ExternalMessageClassification | null;
  };
};

export type SuggestedActionsFeedItem = FeedItemBase & {
  type: FeedItemType.SUGGESTED_ACTIONS;
  data: SuggestedActionsData;
};

export type SuggestedActionsData = {
  actions: SuggestedAction[];
};

export enum SuggestedActionType {
  BUILD_ORDER = 'BUILD_ORDER',
  BUILD_QUOTE = 'BUILD_QUOTE',
  BUILD_REMAINING_ORDERS = 'BUILD_REMAINING_ORDERS',
  DRAFT_QUOTE_REPLY = 'DRAFT_QUOTE_REPLY',
  BATCH_QUOTE_REPLY = 'BATCH_QUOTE_REPLY',
  OPEN_LINK = 'OPEN_LINK',
  MARK_QUOTE_QUOTED = 'MARK_QUOTE_QUOTED',
  MARK_QUOTE_WON = 'MARK_QUOTE_WON',
}

export type SuggestedAction =
  | BuildOrderAction
  | BuildQuoteAction
  | BuildRemainingOrdersAction
  | DraftQuoteReplyAction
  | BatchQuoteReplyAction
  | OpenLinkAction
  | UpdateQuoteStatusAction;

export type BuildOrderAction = {
  type: SuggestedActionType.BUILD_ORDER;
};

export type OpenLinkAction = {
  type: SuggestedActionType.OPEN_LINK;
  url: string;
  title: string;
};

export type BuildQuoteAction = {
  type: SuggestedActionType.BUILD_QUOTE;
};

export type BuildRemainingOrdersAction = {
  type: SuggestedActionType.BUILD_REMAINING_ORDERS;
  orderId: string;
};

export type DraftQuoteReplyAction = {
  type: SuggestedActionType.DRAFT_QUOTE_REPLY;
  quoteId: string;
};

export type BatchQuoteReplyAction = {
  type: SuggestedActionType.BATCH_QUOTE_REPLY;
  jobId: string;
};

export type UpdateQuoteStatusAction = {
  type:
    | SuggestedActionType.MARK_QUOTE_QUOTED
    | SuggestedActionType.MARK_QUOTE_WON;
  quoteId: string;
};
