import { Text } from '@shared/components/text';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';

const ROOT = makeRootClassName('RateErrors');
const el = makeElementClassNameFactory(ROOT);

export const RateError = ({
  errorMessage,
  integrationName,
  fetchedEntity,
}: {
  errorMessage: string;
  integrationName: string;
  fetchedEntity: string;
}) => {
  return (
    <div>
      <Text type="custom" className={el`error-title`}>
        Could not fetch {integrationName} {fetchedEntity}
      </Text>
      <div className={el`error-text`}>{errorMessage}</div>
    </div>
  );
};
