import { useOutlookAdaptor } from '@/contexts/useOutlookAdaptor';
import { useToast } from '@shared/components/toast';
import { useHasAuthorizedIntegration } from '@shared/contexts/pluginContexts/useHasAuthorizedIntegration';
import { AuthTokenType } from '@shared/generated/graphql';
import { compareEmails } from '@shared/utils/emailAddress';
import { useEffect } from 'react';
import { WEB_INTEGRATION_CONIFIGURATION_URL } from '../components/AuthorizeMicrosoftNotice';

export const useAuthorizedAccountsCheck = () => {
  const { sendToast } = useToast();
  const { authorizedEmail } = useHasAuthorizedIntegration({
    type: AuthTokenType.MICROSOFT_GRAPH_API,
  });
  const { sharedInboxOwner } = useOutlookAdaptor();

  useEffect(() => {
    const { emailAddress } = Office.context.mailbox.userProfile;
    if (!authorizedEmail || !emailAddress) return;

    // NOTE(parlato): If the user profile is that of the shared inbox, we do not show an error
    if (compareEmails(sharedInboxOwner, emailAddress)) return;

    if (!compareEmails(emailAddress, authorizedEmail)) {
      sendToast('Authorization error', {
        variant: 'error',
        autoHideDuration: 5000,
        compact: true,
        description: `Authorization granted for ${authorizedEmail}, but you're logged in as ${emailAddress}. Please reauthorize with ${emailAddress} to proceed.`,
        actions: [
          {
            label: `Authorize account`,
            isExternal: true,
            href: WEB_INTEGRATION_CONIFIGURATION_URL ?? '',
            target: '_blank',
          },
        ],
      });
    }
  }, [authorizedEmail, sharedInboxOwner]);
};
